import revive_payload_client_QiKS8q53f3 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9hHwQ4qsy3 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3iwzXqTH8w from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1RVtHQLehx from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.4_rollup@4.17.1_vite@5.2.10_@types+node@20.11.24_sass@1._opri3gxvxwf3pgtadwzykcjl6q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_BTTLyYcUiQ from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_WOL15ft177 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Z8dpSEzNEG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.6.3_vue@3.4.21_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/online-store/.nuxt/components.plugin.mjs";
import prefetch_client_LLY5AQUBfw from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KFrjhdEMwE from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node_trwgye73cxukcoreduclmpycwa/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_1Zx9p3Uwjg from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@t_pwe4ul4xrrp5nkuvspl7jwvz4q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_1l0rry29KY from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@t_pwe4ul4xrrp5nkuvspl7jwvz4q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_apINBpIVJV from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@types+node@20_hiwuz6dgfqlcwlwr3xnmufhhr4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_rvPkl40IRS from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@types+node@20_hiwuz6dgfqlcwlwr3xnmufhhr4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_WQpJCiTO9R from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_qmkq33wukyxh6vajpzh6cxjkg4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_events_client_dLOKnLsuLH from "/app/apps/online-store/plugins/01.events.client.ts";
import _02_cathode_client_Qbf8UvtDFV from "/app/apps/online-store/plugins/02.cathode.client.ts";
import axios_QMFgzss1s4 from "/app/apps/online-store/plugins/axios.ts";
import disable_logs_PLbUQJb9qQ from "/app/apps/online-store/plugins/disable-logs.ts";
import error_handler_kEP5FliEXj from "/app/apps/online-store/plugins/error-handler.ts";
import sentry_client_shVUlIjFLk from "/app/apps/online-store/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/apps/online-store/plugins/vue-query.ts";
export default [
  revive_payload_client_QiKS8q53f3,
  unhead_9hHwQ4qsy3,
  router_3iwzXqTH8w,
  _0_siteConfig_1RVtHQLehx,
  payload_client_BTTLyYcUiQ,
  check_outdated_build_client_WOL15ft177,
  plugin_vue3_Z8dpSEzNEG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LLY5AQUBfw,
  plugin_KFrjhdEMwE,
  titles_1Zx9p3Uwjg,
  defaults_1l0rry29KY,
  siteConfig_apINBpIVJV,
  inferSeoMetaPlugin_rvPkl40IRS,
  chunk_reload_client_WQpJCiTO9R,
  _01_events_client_dLOKnLsuLH,
  _02_cathode_client_Qbf8UvtDFV,
  axios_QMFgzss1s4,
  disable_logs_PLbUQJb9qQ,
  error_handler_kEP5FliEXj,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]