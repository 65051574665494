import { type Static, Type } from '@sinclair/typebox';

export const dropsResponseSchema = Type.Array(
	Type.Object({
		drop_id: Type.Number(),
		brand_id: Type.Number(),
		partner_id: Type.Number(),
		shopify_id: Type.Number(),
		handle: Type.String(),
		title: Type.String(),
		description: Type.String(),
		product_agg_sale_percentage: Type.Optional(Type.Number()),
		product_count: Type.Optional(Type.Number()),
		v24_top_product_image: Type.Optional(Type.String()),
		product_agg_variants_min_price: Type.Optional(Type.Number()),
		brand: Type.Optional(Type.String()),
		brand_name: Type.Optional(Type.String()),
	}),
);

export const categoryTreeResponseSchema = Type.Recursive((This) =>
	Type.Object({
		id: Type.Number(),
		title: Type.String(),
		parent_id: Type.Number(),
		logo: Type.String(),
		image: Type.String(),
		valhalla_score: Type.Number(),
		product_count: Type.Number(),
		node_level: Type.Number(),
		handle: Type.String(),
		children: Type.Optional(Type.Array(This)),
	}),
);

export const categoriesResponseSchema = Type.Array(
	Type.Object({
		id: Type.Number(),
		title: Type.String(),
		parent_id: Type.Number(),
		node_level: Type.Number(),
		is_leaf: Type.Boolean(),
		v24_top_product_image: Type.String(),
		v24_top_product_logo: Type.String(),
		product_count: Type.Number(),
		static_logo: Type.String(),
		description: Type.String(),
		handle: Type.String(),
		meta_description: Type.Optional(Type.String()),
		meta_title: Type.Optional(Type.String()),
		seo_meta_title: Type.Optional(Type.Unknown()),
		seo_meta_description: Type.Optional(Type.Unknown()),
		path: Type.Optional(Type.Union([Type.Null()], Type.Array(Type.String()))),
	}),
);

export const sectionSchema = Type.Object({
	order: Type.Number(),
	page_id: Type.Optional(Type.String()),
	component_id: Type.Optional(Type.Number()),
	component_identifier: Type.String(),
	// TODO: remove this once backend removes it's
	method: Type.String(), // static or dynamic
	meta_data: Type.Object({
		title: Type.String(),
		subtitle: Type.Optional(Type.String()),
		output: Type.Optional(Type.Any()),
		input: Type.Optional(Type.Any()),
		entity: Type.String(),
		method: Type.String(), // static or dynamic
		alignment: Type.Optional(Type.String()),
	}),
	hidden: Type.Optional(Type.Boolean()),
});

export const globalSectionSchema = Type.Object({
	id: Type.Optional(Type.Number()),
	store_id: Type.Number(),
	component_identifier: Type.String(),
	method: Type.String(),
	meta_data: Type.Unknown(),
});

export const globalStoreSchema = Type.Object({
	store: Type.Object({
		id: Type.Number(),
		vendor_id: Type.Union([Type.Number(), Type.Null()]),
		name: Type.Union([Type.String(), Type.Null()]),
		meta_data: Type.Optional(
			Type.Union([
				Type.Object({
					meta_title: Type.Optional(Type.String()),
					meta_description: Type.Optional(Type.String()),
					logo: Type.Optional(Type.String()),
					og_image: Type.Optional(Type.String()),
				}),
				Type.Null(),
			]),
		),
		inbound_marketing: Type.Unknown(),
		add_ons: Type.Union([
			Type.Object({
				wishlist: Type.Optional(Type.Boolean()),
				chatbot: Type.Optional(Type.Boolean()),
				reward_points: Type.Optional(Type.Boolean()),
				'blessed-friday-sale': Type.Optional(
					Type.Object({
						enabled: Type.Optional(Type.Boolean()),
						endTime: Type.Optional(Type.String()),
					}),
				),
				'laam-meta-pixel': Type.Optional(
					Type.Object({
						enabled: Type.Optional(Type.Boolean()),
					}),
				),
			}),
			Type.Null(),
		]),
		store_type: Type.String(),
		brand_ids: Type.Union([Type.Array(Type.Number()), Type.Null()]),
		created_at: Type.String(),
		updated_at: Type.String(),
		domain: Type.Optional(Type.String()),
		store_metadata: Type.Optional(Type.Union([Type.Object({}), Type.Null()])),
	}),
	global_sections: Type.Array(globalSectionSchema),
});
export const pageSchema = Type.Object({
	id: Type.Number(),
	identifier: Type.String(),
	created_at: Type.String(),
	deleted: Type.Boolean(),
});

export const pageSectionsSchema = Type.Object({
	sections: Type.Array(sectionSchema),
	cursor: Type.String(),
});

export const previewPageSectionsSchema = Type.Array(sectionSchema);

export type PageSectionResponse = Static<typeof pageSectionsSchema>;
export type GlobalSection = Static<typeof globalSectionSchema>;
export type Section = Static<typeof sectionSchema>;
export type Page = Static<typeof pageSchema>;
export type PagesResponse = {
	pages: Page[];
	count: number;
};
export type ProductSchema = Static<typeof productSchema>;
export const productSchema = Type.Object({
	brand: Type.String(),
	category: Type.String(),
	compare_at_price: Type.Number(),
	description: Type.String(),
	additional_description: Type.String(),
	discount: Type.Number(),
	drop_handle: Type.String(),
	external_product_id: Type.String(),
	handle: Type.String(),
	product_handling_date: Type.Optional(
		Type.Union([Type.String(), Type.Null()]),
	),
	image: Type.Object({
		alt: Type.String(),
		height: Type.Number(),
		shopify_id: Type.Number(),
		src: Type.String(),
		width: Type.Number(),
	}),
	media: Type.Array(
		Type.Object({
			shopify_id: Type.Number(),
			alt: Type.String(),
			src: Type.String(),
			width: Type.Number(),
			height: Type.Number(),
		}),
	),
	meta: Type.Object({
		cta: Type.String(),
		cart_item_limit: Type.Number(),
	}),
	options: Type.Array(
		Type.Object({
			values: Type.Array(Type.String()),
			name: Type.String(),
		}),
	),
	partner: Type.String(),
	price: Type.Number(),
	new_price: Type.Optional(Type.Number()),
	sale_percentage: Type.Number(),
	shopify_id: Type.Number(),
	size_chart_id: Type.Number(),
	size_chart_name: Type.String(),
	status: Type.String(),
	stock_status: Type.String(),
	tags: Type.Array(Type.String()),
	title: Type.String(),
	total_inventory_quantity: Type.Number(),
	valhalla_score: Type.Number(),
	variants: Type.Array(
		Type.Object({
			shopify_id: Type.Number(),
			price: Type.String(),
			compare_at_price: Type.String(),
			reseller_price: Type.Optional(Type.String()),
			inventory_quantity: Type.Number(),
			stock_status: Type.Optional(Type.String()),
			weight_unit: Type.String(),
			weight: Type.Number(),
			sku: Type.String(),
			title: Type.String(),
		}),
	),
	attributes: Type.Object({
		'bottom-fabrics': Type.String(),
		'color-type': Type.String(),
		'dupatta-fabrics': Type.String(),
		fabric: Type.String(),
		'number-of-pieces': Type.String(),
		'product-type': Type.String(),
		season: Type.String(),
		'shirt-fabrics': Type.String(),
		'trouser-fabrics': Type.String(),
	}),
	node_l1: Type.String(),
	node_l2: Type.String(),
	node_l3: Type.String(),
	node_l4: Type.String(),
});

export type CursorSchema = Static<typeof cursorSchema>;
export const cursorSchema = Type.Object({
	next: Type.String(),
});

export const brandSchema = Type.Array(
	Type.Object({
		id: Type.Number(),
		name: Type.String(),
		handle: Type.String(),
		description: Type.String(),
		logo: Type.String(),
		product_count: Type.Number(),
		product_agg_sale_percentage: Type.Number(),
		meta_description: Type.Optional(Type.String()),
		meta_title: Type.Optional(Type.String()),
		v24_top_product_image: Type.Optional(Type.String()),
	}),
);

export type BrandsResponse = Static<typeof brandSchema>;

export const filtersResponseSchema = Type.Object({
	discounts: Type.Optional(Type.Array(Type.String())),
	sale_percentage: Type.Optional(Type.Array(Type.String())),
	prices: Type.Optional(
		Type.Object({
			min_price: Type.Number(),
			max_price: Type.Number(),
		}),
	),
	size: Type.Optional(
		Type.Mapped(Type.String(), () => Type.Array(Type.String())),
	),
	delivery_days: Type.Optional(Type.Array(Type.String())),
	brands: Type.Optional(Type.Array(Type.String())),
	nodes: Type.Optional(
		Type.Array(
			Type.Object({
				id: Type.Number(),
				title: Type.String(),
				children: Type.Array(
					Type.Object({
						id: Type.Number(),
						title: Type.String(),
					}),
				),
			}),
		),
	),
	'attrs.product-type': Type.Optional(Type.Array(Type.String())),
	fabrics: Type.Optional(
		Type.Object({
			'bottom-fabric': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),

			'dupatta-fabric': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
			fabric: Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
			'fabric painting': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
			'poly fabric': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
			'shirt-fabrics': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
			'trouser-fabrics': Type.Optional(
				Type.Union([Type.Null(), Type.Array(Type.String())]),
			),
		}),
	),
	'attrs.color-type': Type.Optional(Type.Array(Type.String())),
	'attrs.season': Type.Optional(Type.Array(Type.String())),
	'attrs.number-of-pieces': Type.Optional(Type.Array(Type.String())),
	'attrs.work-technique': Type.Optional(Type.Array(Type.String())),
});

export type FiltersResponseSchema = Static<typeof filtersResponseSchema>;

export type ProductsResponseSchema = Static<typeof productsResponseSchema>;
export const productsResponseSchema = Type.Object({
	products: Type.Array(productSchema),
	cursor: cursorSchema,
});

export type DropsResponse = Static<typeof dropsResponseSchema>;
export type CategoryTreeResponse = Static<typeof categoryTreeResponseSchema>;
export type CategoriesResponse = Static<typeof categoriesResponseSchema>;
export type GlobalStoreResponse = Static<typeof globalStoreSchema>;

export const SectionsResponse = Type.Array(sectionSchema);

export type SectionSchema = Static<typeof SectionsResponse>[number];

export type SectionsResponse = Array<SectionSchema & { uuid: string }>;
