import { useBreakpoints } from '@vueuse/core';
import { UAParser } from 'ua-parser-js';
import { productSchema, type ProductSchema } from '@laam/cms-shared';
import { PDPDynamicTitleEnum, PDPDynamicImageTitleEnum } from '../utils/enums';
import { StatsigAdapter } from '@laam/events';
import { useCountryCode } from '~/stores/country';
import { validatorFactory } from '@laam/lib/validator';
import { capitalizeFirstWord } from './address';

export function getDate(handlingDate: string) {
	const date: Date = new Date(handlingDate);
	const dateString: string[] = date.toDateString().split(' ');
	return dateString[1] + ' ' + dateString[2] + ', ' + dateString[3];
}

export const getQueryFromPath = (path: string) => {
	const queryPart = path.split('?')[1];
	if (!queryPart) return {};
	const query = queryPart.split('&');
	const acc: { [key: string]: string } = {};
	query.forEach((q) => {
		const [key, value] = q.split('=');
		acc[key!] = value!;
	});
	return acc;
};

export function isMobileView() {
	const breakpoints = {
		mobile: 0,
		desktop: 768,
	};

	const breakpointsList = useBreakpoints(breakpoints);

	const mobileView = !breakpointsList.desktop.value;

	return mobileView;
}

export const getVariantShippingDaysDifference = (date: string) => {
	const currentDate = new Date();
	const handlingDate = new Date(date);

	const timeDifference = handlingDate.getTime() - currentDate.getTime();

	return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export function getKeyFromValue(
	obj: { [key: string]: unknown },
	value: string,
) {
	try {
		for (const key in obj) {
			if (obj[key] && obj[key] === value) {
				return key;
			}
		}
		return null; // Return null if the value is not found
	} catch (error) {
		console.log(error);
		return null;
	}
}

export const callWithRetries = async <T>(fn: () => T) => {
	let retries = 0;
	const maxRetries = 3;
	let initialised = false;

	while (retries < maxRetries) {
		try {
			return fn();
		} catch (error) {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			retries++;
		}

		// If max retries reached and not initialized, initialize StatsigAdapter
		if (retries >= maxRetries && !initialised) {
			const instance = new StatsigAdapter();
			const config = useRuntimeConfig();
			const { countryCode } = useCountryCode();
			const user = localStorage.getItem('logan')
				? JSON.parse(localStorage.getItem('logan')!)
				: null;
			await instance.init({
				sdkKey: config.public.statsigSdkKey,
				environment: config.public.statsigEnvironment,
				countryCode: countryCode,
				user: {
					userID: user ? (user.isSignedIn ? user.loganId : null) : null,
					email: user ? (user.isSignedIn ? user.email : null) : null,
				},
			});
			// Reset retries and mark as initialized
			retries = 0;
			initialised = true;
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
	}

	throw new Error(
		'Failed to call function after retries even after initialization',
	);
};

export const splitPhoneString = (phoneString: string) => {
	// Use regular expression to capture last 10 digits
	const match = phoneString.match(/(\d{10})$/);

	if (match) {
		// Extract the last 10 digits and the rest of the string
		const last10Digits = match[1];
		if (!last10Digits) {
			throw new Error('last10Digits are not defined');
		}
		let restOfString = phoneString.slice(0, -last10Digits.length);
		// Remove '+' or '-' from restOfString
		restOfString = restOfString.replace(/[+\-]/g, ''); // eslint-disable-line
		return {
			number: last10Digits,
			prefix: restOfString,
		};
	} else {
		// No last 10 digits found
		return {
			number: phoneString,
			prefix: null,
		};
	}
};

export function getMoengageUserId() {
	let data: any = localStorage.getItem('MOE_DATA');
	if (!data) return null;
	data = JSON.parse(data);
	if (!data) return null;
	const userData = data.USER_DATA;
	if (!userData) return null;
	const userAttributes = userData.attributes;
	if (!userAttributes || !userAttributes?.length) return null;
	return userAttributes.find((attr: any) => {
		if (attr.key === 'USER_ATTRIBUTE_UNIQUE_ID') {
			return true;
		} else return false;
	})?.value;
}

export function extractCountryCode(headerValue: string) {
	try {
		const parts = headerValue.split(',');
		if (parts.length >= 2) {
			const countryCode = parts[0]!.trim();
			return countryCode;
		} else {
			return null;
		}
	} catch (error) {
		return null;
	}
}

export const convertQueryStringToObject = (queryString: string) => {
	if (queryString === '') {
		return {};
	}
	const pairs = queryString.split('&');
	const result = new Map();
	pairs.forEach((pair) => {
		const [key, value] = pair.split('=');
		result.set(key, value);
	});

	return Object.fromEntries(result.entries());
};

// helpers.ts

export function getDeviceType(ua: string): string | undefined {
	const parser = new UAParser(ua);
	const result = parser.getResult();
	if (result.device.type) {
		return result.device.type;
	} else if (result.os.name === 'Android' || result.os.name === 'iOS') {
		return 'mobile';
	} else if (result.browser.name?.toLowerCase().includes('mobile')) {
		return 'mobile';
	}

	return undefined;
}

export const cast = <T>(value: unknown): T => value as T;

export const getStoreIdentifier = (storeIdParam?: string) => {
	if (storeIdParam) {
		return storeIdParam;
	}
	if (process?.client) {
		// check if it's prod
		if (useRuntimeConfig().public.env === 'production') {
			// return current domain
			return window.location.hostname.replace('www.', '');
		} else {
			// return store id
			return useRuntimeConfig().public.storeId;
		}
	} else {
		// check if it's prod
		if (process.env.NUXT_PUBLIC_ENV === 'production') {
			// return current domain
			return useRequestURL().hostname.replace('www.', '');
		} else {
			// return store id
			return process.env.NUXT_PUBLIC_STORE_ID;
		}
	}
};
//function to measure dynamic length of seo description
export const clampedTextLength = (text: string, isMobile: boolean) => {
	const tempDiv = document.createElement('div');

	tempDiv.textContent = text;

	if (isMobile) {
		tempDiv.style.width = `${String(window.innerWidth)}px`;
		tempDiv.style.paddingLeft = '16px';
		tempDiv.style.paddingRight = '16px';
	} else {
		const screenWidth = window.innerWidth;

		//desktop screen without margins
		if (screenWidth <= 1282) {
			tempDiv.style.width = `${String(screenWidth - 250 - 6 - 6)}px`; //subtracting screen width by sidebar width and content x-axis padding
		}
		//desktop screen with margins
		else {
			const maxWidth = 1032;
			const margin = screenWidth - (maxWidth + 250); //calculating margin by summing max width with sidebar width and subtracting with screen width
			tempDiv.style.width = `${String(screenWidth - margin - 250 - 6 - 6)}px`;
		}
		tempDiv.style.paddingLeft = '48px';
		tempDiv.style.paddingRight = '48px';
	}
	tempDiv.style.fontSize = '14px';
	tempDiv.style.lineHeight = '1.25rem';

	tempDiv.style.height = '40px';

	document.body.appendChild(tempDiv);

	const maxHeight = parseInt(tempDiv.style.height, 10);
	let visibleText = text;

	while (tempDiv.scrollHeight > maxHeight && visibleText.length > 0) {
		visibleText = visibleText.slice(0, -1);
		tempDiv.textContent = visibleText;
	}

	document.body.removeChild(tempDiv);
	const textLength = visibleText.length;
	if (visibleText.length === text.length) return text.length;
	else return visibleText.slice(0, textLength - 18).length;
};

export const plpSeoDescription = (
	text: string,
	showMore: boolean,
	isMobile: boolean,
) => {
	const numberOfWords = clampedTextLength(text, isMobile);
	if (text.length > numberOfWords && !showMore) {
		return text.slice(0, numberOfWords).concat('...');
	} else return text;
	// if (text.length > 250 && !showMore) {
	// 	return text.slice(0, 250).concat('...');
	// } else return text;
};

export const productTitle = (productData: ProductSchema, isMeta?: boolean) => {
	if (isMeta === true) {
		let tempTitle = '';
		PDPDynamicTitleEnum.forEach((element: string) => {
			let value = '';

			// in productData or in productData.attributes
			if (productData[element as keyof typeof productData]) {
				value =
					(productData[element as keyof typeof productData] as string) ?? '';
			} else if (
				productData.attributes[element as keyof typeof productData.attributes]
			) {
				value =
					productData.attributes[
						element as keyof typeof productData.attributes
					];
			}

			// for before node_l1, by before brand
			if (element === 'node_l1' && value) {
				tempTitle += `for ${value} `;
			} else if (element === 'node_l4' && value) {
				if (value.toLowerCase().includes('unstitched'))
					tempTitle += `${value} dress `;
				else tempTitle += `${value} `;
			} else if (element === 'brand' && value) {
				tempTitle += `by ${value} `;
			} else if (element === 'season' && value) {
				tempTitle += `- ${value} `;
			} else if (element === 'number-of-pieces' && value) {
				tempTitle += `- ${value} `;
			} else if (value) {
				tempTitle += `${capitalizeFirstWord(value)} `;
			}
		});

		return tempTitle;
	} else return `${productData.title} - ${productData.brand}`;
};

export const productImgAltTag = (productData: ProductSchema) => {
	let tempTitle = '';
	PDPDynamicImageTitleEnum.forEach((element: string) => {
		let value = '';
		// in productData or in productData.attributes
		if (productData[element as keyof typeof productData]) {
			value =
				(productData[element as keyof typeof productData] as string) ?? '';
		} else if (
			productData.attributes &&
			productData.attributes[element as keyof typeof productData.attributes]
		) {
			value =
				productData.attributes[element as keyof typeof productData.attributes];
		}
		// for before node_l1, by before brand
		if (element === 'node_l1' && value) {
			tempTitle += `for ${value} `;
		} else if (element === 'node_l4' && value) {
			if (value.toLowerCase().includes('unstitched'))
				tempTitle += `${value} dress `;
			else tempTitle += `${value} `;
		} else if (element === 'brand' && value) {
			tempTitle += `by ${value} `;
		} else if (value) {
			tempTitle += `${capitalizeFirstWord(value)} `;
		}
	});
	return tempTitle;
};

export const replaceHtmlTags = (str: string): string => {
	return str.replace('\n', ',').replace(/<[^>]*>?/gm, '');
};

export const isWholeNumber = (num: number | string) => {
	return Math.abs(typeof num === 'number' ? num : parseFloat(num)) % 1 === 0;
};

export const isOriginalProductPrice = (
	compareAtPrice: number,
	price: number,
) => {
	if (compareAtPrice === 0 || compareAtPrice === price) {
		return true;
	}

	return false;
};

export const computeSubstring = (ogString: string, subString: string) => {
	if (ogString) {
		const strLeft = ogString.slice(0, subString.length); //slicing the substring from original string
		const strRight = ogString.slice(subString.length); //slicing original string ahead of substring

		if (strLeft.toLowerCase() === subString.toLowerCase()) {
			//verifying if sliced substring is equal to the input substring
			return {
				strLeft,
				strRight,
			};
		}

		return {
			strLeft: '',
			strRight: ogString,
		};
	}

	return {
		strLeft: '',
		strRight: ogString,
	};
};

export const getSearchQueryPath = (
	filters: string,
	queryNode: number | null,
	selectedNode: number | null,
	queryInput: string,
	pathName: string,
) => {
	let searchQueryPath = `/search?search=${queryInput}`;

	if (filters && pathName === '/search') {
		searchQueryPath += `&${filters}`;
	}
	if (queryNode) {
		searchQueryPath += `&node_id=${queryNode}`;
	} else if (selectedNode) {
		searchQueryPath += `&node_id=${selectedNode}`;
	}

	return searchQueryPath;
};

export function isProductSchema(data: any): data is ProductSchema {
	const validator = validatorFactory<ProductSchema>(productSchema);
	if (validator.verify(data)) {
		return true;
	}
	return false;
}

export function containCapitalLetters(value: string) {
	return /[A-Z]/.test(value);
}
