import {
	type Section,
	type GlobalStoreResponse,
	type OctaneFooterData,
	type OctaneHeaderData,
} from '@laam/cms-shared';
import { defineStore } from 'pinia';
import { fetchConversionRates, fetchStore } from '~/data/global-store';
import type { AddOns } from '~/types/store';

export type ConversionRates = {
	[key: string]: {
		exchange_rate_to_pk: number;
		exchange_rate_to_usd: number;
	};
};

export const useGlobalStore = defineStore('global', () => {
	const store = useState<GlobalStoreResponse['store']>();
	const $conversionRates = useState<ConversionRates>();
	const storeType = ref<string | null>(null);
	const header = ref<OctaneHeaderData | null>(null);
	const footer = ref<OctaneFooterData | null>(null);
	const sections = ref<Section[]>([]);
	const domain = ref<string | null>(null);

	const addOns = computed(() => {
		return store.value.add_ons as AddOns;
	});

	const headerLogo = computed(() => {
		if (!header.value) return null;
		return header.value.logo.url;
	});

	const initializeHeader = (
		globalSections: GlobalStoreResponse['global_sections'],
	) => {
		const headerSection = globalSections.find(
			(section) => section.component_identifier === 'header',
		);
		if (headerSection) {
			header.value = headerSection.meta_data as OctaneHeaderData;
		}
	};

	const initializeFooter = (
		globalSections: GlobalStoreResponse['global_sections'],
	) => {
		const footerSection = globalSections.find(
			(section) => section.component_identifier === 'footer',
		);
		if (footerSection) {
			footer.value = footerSection.meta_data as OctaneFooterData;
		}
	};

	const fetchStoreConfigAndGlobalSections = async () => {
		let globalSections = null;
		let globalStore = null;
		if (process.env.STORE_TYPE === 'octane') {
			const siteConfig = useSiteConfig();
			globalStore = await siteConfig.store;
			globalSections = await siteConfig.global_sections;
		} else {
			const { store, global_sections } = (await fetchStore())!;
			if (!store) throw new Error('Failed to fetch global store');
			globalStore = store;
			globalSections = global_sections;
		}
		const currencyRates = await fetchConversionRates(globalStore.domain!);
		store.value = globalStore;
		storeType.value = globalStore.store_type;
		$conversionRates.value = currencyRates;
		if (storeType.value === 'OCTANE') {
			initializeHeader(globalSections);
			initializeFooter(globalSections);
		}
	};

	const setDomain = () => {
		domain.value = store.value.domain!;
	};

	return {
		store,
		storeType,
		$conversionRates,
		fetchStoreConfigAndGlobalSections,
		header,
		footer,
		sections,
		headerLogo,
		domain,
		addOns,
		setDomain,
	};
});
