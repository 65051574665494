<script setup lang="ts">
import ExpressToggle from './ExpressToggle.vue';
import SizeFilter from './SizeFilter.vue';
import DiscountFilter from './DiscountFilter.vue';
import BrandFilter from './BrandFilter.vue';
import PriceFilter from './PriceFilter.vue';
import AllFilter from './AllFilter.vue';
import { useForm } from 'vee-validate';
import { useFilters } from '~/data/filters';
import StylesFilter from './StylesFilter.vue';
import DeliveryFilter from './DeliveryFilter.vue';
import { removeEmptyFilters, triggerFilterEvents } from './utils';
import { useRoute, useRouter } from 'vue-router';
import { getNodeIdFromHandle } from '~/utils/node-id-from-handle';
import OccasionsFilter from './OccasionsFilter.vue';
import FabricFilter from './FabricFilter.vue';
import ColorFilter from './ColorFilter.vue';
import SeasonsFilter from './SeasonsFilter.vue';
import { useGlobalStore } from '~/stores/global';
import { isNewFiltersGateEnabled } from '~/utils/featureFlags';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import {
	filterSchema,
	type FilterSchema,
	type FiltersResponseSchema,
} from '@laam/cms-shared';
import { toTypedSchema } from '@vee-validate/zod';
import { applyFilters, getFiltersFromUrl } from '@laam/ui';
import type { BannerReasons } from '../TopBanner.vue';
import { useDebounceFn } from '@vueuse/core';
import { StatsigSingleton } from '@laam/statsig-client';

const router = useRouter();
const route = useRoute();

const { storeType } = useGlobalStore();

const discountExpCheck = ref(false);

const appliedFilterParams = ref<string>(route?.fullPath?.split('?')[1] || '');

interface FilterBarProps {
	handle?: string;
	class?: string;
	nodeIds?: Array<number>;
	static?: boolean;
	filtersFromProp?: string;
}
const props = defineProps<FilterBarProps>();

const getParams = computed(() => {
	if (route.path.includes('/brands') || route.path.includes('/drops')) {
		return props.handle ? { brand_handle: props.handle! } : {};
	} else if (route.path.includes('/node')) {
		return props.handle ? { node_id: getNodeIdFromHandle(props.handle!)! } : {};
	} else if (route.path.includes('/collections')) {
		return props.handle ? { collection_handle: props.handle! } : {};
	} else if (route.path.includes('/set')) {
		return {
			node_id: props.nodeIds!,
		};
	} else if (
		['identifier', 'men', 'kids', 'all-products'].includes(
			route.name!.toString(),
		)
	) {
		return props.nodeIds
			? {
					node_id: props.nodeIds?.join(',') ?? '',
				}
			: {};
	} else if (route.path.includes('/search')) {
		return props.handle ? { search_term: props.handle } : {};
	} else {
		return null;
	}
});

// get data filters data composable
const { data: filters, isLoading } = useFilters(getParams, appliedFilterParams);
const appliedFilters = ref<FilterSchema>();
const newFiltersEnabled = ref(false);

const isHeaderVisible = inject('headerVisible') as Ref<boolean>;

const hasCategories = (
	filter: FiltersResponseSchema[keyof FiltersResponseSchema],
) => {
	if (filter) {
		return !Object.values(filter).every(
			(category) =>
				!category ||
				Object.keys(category).length === 0 ||
				Object.keys(category) === null,
		);
	}
	return false;
};
// initial filters from url
const setFiltersFromUrl = () => {
	const url = window.location.href;
	const filtersFromUrl = getFiltersFromUrl(url);
	appliedFilters.value = filtersFromUrl;
	newFiltersEnabled.value = isNewFiltersGateEnabled() ?? false;
	if (props.filtersFromProp)
		appliedFilterParams.value += `&${props.filtersFromProp}`;
};

onMounted(() => {
	discountExpCheck.value = StatsigSingleton.getExperiment(
		'discount-values-change',
	).get('newDiscountValues', false);
	setFiltersFromUrl();
});

const { handleSubmit, values, setValues, validate } = useForm({
	validationSchema: toTypedSchema(filterSchema),
	initialValues: {
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
	},
	keepValuesOnUnmount: true,
});

watch(appliedFilters, (value) => {
	setValues({
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
		sale_percentage: undefined,
		sort_by: undefined,
		price: undefined,
		...value,
	});
});

const filterCount = computed(() => {
	const keys = Object.keys(values || {}).filter((key) => key !== 'search');
	let count = 0;
	for (const key of keys) {
		const val = values?.[key as keyof FilterSchema];
		if (key == 'sort') {
			continue;
		} else if (key == 'price' && val) {
			const price = val as FilterSchema['price'];
			if (
				price!.from !== filters.value?.prices?.min_price ||
				price!.to !== filters.value?.prices?.max_price
			)
				count++;
		} else if (key === 'sale_percentage' && val) {
			count++;
		} else {
			if (val && Array.isArray(val)) count += val.length;
		}
	}
	return count;
});

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	appliedFilterParams.value = route.fullPath.split('?')[1] || '';
}, 200);

watch(route, (route) => {
	if (
		![
			'brands',
			'all-products',
			'nodes',
			'drops',
			'identifier',
			'collections',
		].some((rn) => route.name!.toString().includes(rn))
	) {
		return;
	}
	debounce(route);
});

const onSubmit = (payload?: Event) => {
	payload?.preventDefault();
	handleSubmit(async (values) => {
		const url = window.location.href;
		const origin = new URL(url).origin;
		const newFilters = removeEmptyFilters(values, filters.value!);
		let newPath = applyFilters(url, newFilters);
		triggerFilterEvents(newFilters, origin + newPath);

		// if we are on search page and we have search query
		if (route.path === '/search' && route.query.search) {
			const searchTerm = route.query.search;
			const nodeId = route.query.node_id;
			const [basePath, queryString] = newPath.split('?');
			if (queryString === '') {
				newPath = `${basePath}?search=${searchTerm}`;
			} else {
				newPath = `${basePath}?search=${searchTerm}&${queryString}${nodeId ? `&node_id=${nodeId}` : ''}`;
			}
		}
		router.replace(newPath);
	})();
};

const resetFilters = () => {
	setValues({
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
		sale_percentage: undefined,
		sort_by: undefined,
		price: undefined,
	});
	onSubmit();
};

provide('filters', filters);
provide('validate', validate);
provide('filterCount', filterCount);
provide('newFiltersEnabled', newFiltersEnabled);
provide('setFiltersFromUrl', setFiltersFromUrl);
provide('discountExpCheck', discountExpCheck);

const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
</script>
<template>
	<div v-if="isLoading">
		<FilterBarSkeleton />
	</div>
	<form
		v-else-if="filters"
		id="filterForm"
		class="z-[9] transition-all bg-white duration-75 filter_bar"
		:class="{
			static: static,
			sticky: !static,
		}"
		:data-store-type="storeType"
		:data-header-visible="isHeaderVisible"
		:data-top-banner="!!topBannerReason"
		data-testid="plp-filter-bar"
		@submit="onSubmit"
	>
		<div class="p-3xl flex gap-md overflow-scroll no-scrollbar lg:px-none">
			<AllFilter @reset-form="resetFilters" />
			<ExpressToggle @update:express-toggle="onSubmit" />
			<!-- Fabric Button -->
			<FabricFilter
				v-if="newFiltersEnabled && hasCategories(filters?.fabrics)"
				@reset-form="resetFilters"
			/>
			<!-- Price Button -->
			<PriceFilter @reset-form="resetFilters" />
			<!-- Size Button -->
			<SizeFilter
				v-if="hasCategories(filters?.size)"
				@reset-form="resetFilters"
			/>
			<!-- Color Button -->
			<ColorFilter v-if="newFiltersEnabled" @reset-form="resetFilters" />
			<!-- Style Button -->
			<StylesFilter
				v-if="hasCategories(filters?.nodes)"
				@reset-form="resetFilters"
			/>
			<!-- Brand Button -->
			<BrandFilter @reset-form="resetFilters" />
			<!-- Season Button -->
			<SeasonsFilter v-if="newFiltersEnabled" @reset-form="resetFilters" />
			<!-- Occasion Button -->
			<OccasionsFilter @reset-form="resetFilters" />
			<!-- Discount Button -->
			<DiscountFilter @reset-form="resetFilters" />
			<!-- Delivery Button -->
			<DeliveryFilter @reset-form="resetFilters" />
		</div>
	</form>
</template>

<style scoped>
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='true'][data-top-banner='true'] {
	top: calc(
		var(--plp-header-height-mobile) + var(--laam-shipping-banner-height)
	);
	@media (min-width: 768px) {
		top: calc(
			var(--laam-topbar-height) + var(--laam-shipping-banner-height) +
				var(--plp-header-height)
		);
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='true'][data-top-banner='false'] {
	top: var(--plp-header-height-mobile);
	@media (min-width: 768px) {
		top: calc(var(--plp-header-height) + var(--laam-topbar-height));
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='false'][data-top-banner='true'] {
	top: var(--laam-shipping-banner-height);
	@media (min-width: 768px) {
		top: calc(var(--laam-topbar-height) + var(--laam-shipping-banner-height));
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='false'][data-top-banner='false'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--laam-topbar-height);
	}
}
.filter_bar[data-store-type='OCTANE'][data-header-visible='true'] {
	top: var(--octane-topbar-height-mobile);
	@media (min-width: 768px) {
		top: calc(var(--plp-header-height) + var(--octane-topbar-height));
	}
}

.filter_bar[data-store-type='OCTANE'][data-header-visible='false'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--octane-topbar-height);
	}
}
</style>
