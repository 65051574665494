<script setup lang="ts">
import { StatsigSingleton } from '@laam/statsig-client';
import type { NewArrivalsExperiment } from '~/utils/use-statsig-experiment';

const newArrivalsExperiment = ref<NewArrivalsExperiment>();

onMounted(async () => {
	newArrivalsExperiment.value = StatsigSingleton.getExperiment(
		'new-arrival-plp',
	).value as NewArrivalsExperiment;
});

provide(newArrivalExperimentInjectionKey, newArrivalsExperiment);
</script>
<template>
	<slot />
</template>
