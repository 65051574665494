<script setup lang="ts">
import {
	SheetTrigger,
	Sheet,
	SheetContent,
	SheetHeader,
	DropdownMenu,
	DropdownMenuTrigger,
	Button,
	DropdownMenuContent,
} from '@laam/ui/base';
import CountryIcon from './CountryIcon.vue';
import { PhCaretDown } from '@phosphor-icons/vue';
import countries from '~/utils/countries.json';
import { COUNTRY_CURRENCY_MAP } from '~/utils/enums';
import { useFieldValue } from 'vee-validate';
import SelectorBody from './SelectorBody.vue';

interface Selector {
	type: 'currency' | 'country';
	dataTestid?: string;
}
const props = defineProps<Selector>();

const code = useFieldValue(
	props.type === 'country' ? 'updateCountryTo' : 'updateCurrencyTo',
) as Ref<string>;

const open = ref(false);

const name = computed(() => {
	if (props.type === 'country') {
		return countries[code.value as keyof typeof countries].name;
	} else {
		return code.value.toUpperCase();
	}
});

const countryCode = computed(() => {
	if (props.type === 'country') {
		return code.value;
	} else {
		return COUNTRY_CURRENCY_MAP[code.value as keyof typeof COUNTRY_CURRENCY_MAP]
			.countryCode;
	}
});

const headerTitle = computed(() => {
	if (props.type === 'country') {
		return 'Country/Region';
	} else {
		return 'Currency';
	}
});
const close = () => {
	open.value = false;
};
</script>
<template>
	<Sheet v-if="isMobileView()" v-model:open="open" @update:open="open != open">
		<SheetTrigger :as-child="true" @click="open = true">
			<div
				class="flex py-lg px-2xl items-center rounded-medium border border-gray-300"
			>
				<div class="flex items-center flex-1 gap-md">
					<CountryIcon :code="countryCode" />
					<p class="text-gray-900 text-md w-full">{{ name }}</p>
				</div>
				<PhCaretDown />
			</div>
		</SheetTrigger>
		<SheetContent
			side="bottom"
			class="h-[580px] rounded-t-xl"
			:class="{
				'max-h-[24vh] overflow-y-hidden': props.type === 'currency',
			}"
		>
			<SheetHeader
				class="border-none"
				:tabindex="props.type === 'currency' ? 1 : 0"
			>
				{{ headerTitle }}
			</SheetHeader>
			<ClientOnly>
				<SelectorBody :type="type" @close="close" />
			</ClientOnly>
		</SheetContent>
	</Sheet>
	<DropdownMenu v-else v-model:open="open" @update:open="open != open">
		<DropdownMenuTrigger
			:class="{ dropdown: open }"
			:as-child="true"
			:data-testid="dataTestid"
			@click="open = true"
		>
			<Button
				class="w-full"
				variant="dropdown"
				size="dropdown"
				:as-child="true"
			>
				<div class="flex items-center">
					<div class="flex items-center flex-1 gap-md hover:cursor-pointer">
						<CountryIcon :code="countryCode" />
						<p class="text-gray-900 text-md w-full">{{ name }}</p>
					</div>
					<PhCaretDown />
				</div>
			</Button>
		</DropdownMenuTrigger>
		<DropdownMenuContent class="w-full bg-white max-h-max">
			<SelectorBody
				:class="{
					'max-h-[14vh] overflow-y-hidden': props.type === 'currency',
				}"
				:type="type"
				class="w-[352px] overflow-y-hidden"
				@close="close"
			/>
		</DropdownMenuContent>
	</DropdownMenu>
</template>

<style>
.dropdown {
	outline: none;
	box-shadow:
		0 0 0 4px rgba(163, 163, 163, 0.14),
		0 0 2px rgba(20, 20, 20, 0.05) !important;
}
</style>
