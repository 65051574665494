import { defineStore } from 'pinia';
import {
	useGetCartById,
	useCreateCart,
	useRemoveCartItem,
	useUpdateCartItem,
	useAddToCart,
	useUpdateCheckoutItem,
} from '~/data/cart';
import { useShippingDatesEstimator } from '~/data/shipping-date-estimator.ts';
import { useQueryClient } from '@tanstack/vue-query';
import { type CartDataType } from '~/components/cart/types';
import { useLocalStorage } from '@vueuse/core';
import type { ProductVariant } from '~/types/Product';
import { useLoganStore } from '~/stores/logan.ts';
import { StatsigSingleton } from '@laam/statsig-client';
import type { CartItemMockSchema } from '~/types/LineItems.ts';
import type { LineItemsStore } from '@laam/lib/types';
import { useCountryCode } from './country';
import { isOriginalProductPrice } from '~/utils/helpers.ts';
import { localeStringToNum } from '~/utils/checkout';
import { useCurrency } from '~/stores/currency.ts';
import { storeToRefs } from 'pinia';

export const useCartStore = defineStore('cart', () => {
	const LoganStore = useLoganStore();
	const cartId = ref('');
	const cartIdLocal = ref('');
	const checkoutUrl = ref('');
	const totalAmount = ref();
	const totalVariantCount = ref(0);
	const totalDuty = ref('');
	const totalTax = ref('');
	// Once a user store is created, these 3 items will be fetched from there
	const customerEmail = ref('');
	const accessToken = ref('');
	const loganId = ref('');

	const updateCartFromPdpLoading = ref(false);

	const checkoutCartItemsDiff = ref<boolean | null>(null);
	const showAddedToBag = ref(false);
	const showViewBag = ref(false);
	const viewBagTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null);
	const addedToBagTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null);

	const CurrencyStore = useCurrency();

	const { getFormattedPrice } = CurrencyStore;
	const { currencyCode } = storeToRefs(CurrencyStore);

	const checkoutLocalization = ref<boolean>(true);

	const route = useRoute();

	const path = computed(() => {
		return route.path;
	});

	const persist = () => {
		localStorage.setItem('cartId', cartId.value);
	};
	const hydrate = () => {
		const cartIdH = useLocalStorage('cartId', '');

		customerEmail.value = LoganStore.email ?? null;
		loganId.value = LoganStore.loganId ?? null;
		accessToken.value = LoganStore.accessToken ?? null;

		cartId.value = cartIdH.value;
	};
	onBeforeMount(() => {
		hydrate();
	});
	// queries
	const queryClient = useQueryClient();
	const { data: getCartQuery, refetch: refetchCart } = useGetCartById(
		cartId,
		customerEmail,
		loganId,
		accessToken,
	);

	const ipCountryCode = useCountryCode().countryCode;

	const headers = computed(() => {
		return {
			'Content-Type': 'application/json',
			'X-Country': ipCountryCode! || 'PK',
			'stable-id': process.client ? StatsigSingleton.getStableID() : '',
			'X-User': LoganStore.loganId,
		};
	});

	// mutations
	const { mutate: createCartMutation, isPending: createPending } =
		useCreateCart();
	const { mutate: removeCartItemMutation, isPending: removePending } =
		useRemoveCartItem();
	const { mutateAsync: updateCartItemMutation, isPending: updatePending } =
		useUpdateCartItem();
	const { mutateAsync: addToCartMutation, isPending: addPending } =
		useAddToCart();
	const {
		mutate: updateCheckoutItemMutation,
		isPending: checkoutItemUpdatePending,
	} = useUpdateCheckoutItem(headers);

	// states
	const lineItems = ref<LineItemsStore[]>([]);
	const outOfStockLineItems = ref<LineItemsStore[]>([]);
	const checkoutLineItemResponse = ref<LineItemsStore[]>([]);
	const cart = ref<CartDataType>();

	// // getters

	const getCartById = async () => {
		await refetchCart();
		const cartValues = getCartQuery.value;
		if (!cartValues || !cartValues.data || !cartValues.data.id) {
			return;
		}
		cart.value = cartValues.data;
		if (cartId.value !== cart.value.id) {
			cartId.value = cart.value.id;
			queryClient.setQueryData(['cartId', cartId], cart.value);
			persist();
		}
	};

	const cartEmpty = computed(() => {
		const cartValue = cart.value;
		if (!cartValue) {
			return true;
		}
		return cartValue.totalQuantity === 0;
	});
	const getItemsCount = computed(() => {
		const cartValue = cart.value;
		if (!cartValue) {
			return 0;
		}
		return cartValue.totalQuantity;
	});
	const loading = computed(() => {
		const loading =
			createPending.value || addPending.value || updateCartFromPdpLoading.value;
		return loading;
	});
	const updateLoading = computed(() => {
		return (
			checkoutItemUpdatePending.value ||
			removePending.value ||
			updatePending.value
		);
	});

	// watcher
	watch(
		[checkoutCartItemsDiff, getCartQuery],
		([checkoutCartItemsDiffVal, newValue], [, oldValue]) => {
			if (!newValue) {
				return;
			} else if (newValue !== oldValue && newValue.data !== undefined) {
				if (path.value.includes('checkout') && checkoutCartItemsDiffVal) {
					return;
				} else {
					setCartData(newValue.data);
					cart.value = newValue.data;
				}
			}
		},
	);

	const nativeCartAmount = computed(() => {
		if (checkoutLocalization.value) {
			let subtotal = 0;
			for (const item of lineItems.value) {
				if (!item.new_price) {
					const originalPrice = isOriginalProductPrice(
						parseFloat(item?.compareAtPrice || '0'),
						parseFloat(item.price),
					); //round up usd to .99 and any other currency other than pkr to .00 ceil in case of original price
					const noRound = !(originalPrice && currencyCode.value !== 'pkr'); //round the values in case of non pkr currency and if product has original price
					const convertedPrice = localeStringToNum(
						getFormattedPrice(parseFloat(item?.price), noRound),
					);
					subtotal = subtotal + convertedPrice * item.quantity;
				} else {
					const convertedPrice = localeStringToNum(
						getFormattedPrice(item?.new_price, currencyCode.value !== 'pkr'),
					);
					subtotal = subtotal + convertedPrice * item.quantity;
				}
			}

			return subtotal;
		}

		return parseFloat(totalAmount.value);
	});

	watch(checkoutLineItemResponse, (newVal) => {
		if (newVal && newVal.length > 0) {
			if (lineItems.value.length > 0) {
				const items = newVal.map((item) => {
					if (
						cart.value !== undefined &&
						cart.value.totalQuantity > 0 &&
						lineItems.value.find((cartIt) => cartIt.id === item.id)
					) {
						return {
							...item,
							lineId: lineItems.value.find((cartIt) => cartIt.id === item!.id)!
								?.lineId,
						};
					} else {
						return {
							...item,
						};
					}
				});
				lineItems.value = items;
			} else {
				lineItems.value = newVal;
			}
		} else {
			lineItems.value = newVal;
		}
	});

	watch(
		() => LoganStore.isSignedIn,
		async () => {
			customerEmail.value = LoganStore.email ?? null;
			loganId.value = LoganStore.loganId ?? null;
			accessToken.value = LoganStore.accessToken ?? null;
			if (cartId.value) {
				await refetchCart();
			} else {
				await createCart();
			}
		},
	);

	// actions

	const setCartData = async (res: CartDataType) => {
		// const customerStore = useCustomerStore();
		const items: LineItemsStore[] = [];
		const emptyItems: LineItemsStore[] = [];
		checkoutUrl.value = res?.checkoutUrl;
		totalAmount.value = res.cost.totalAmount.amount;
		totalVariantCount.value = res.totalQuantity;

		if (res.cost.totalDutyAmount) {
			totalDuty.value = res.cost.totalDutyAmount;
		}
		if (res.cost.totalTaxAmount) {
			totalTax.value = res.cost.totalTaxAmount;
		}
		if (!res.lines) {
			throw new Error('res.lines are undefined');
		}

		if (res.lines.length > 0) {
			for (const item of res.lines) {
				// Check if an item with the same ID already exists in the items array
				const existingIndex = items.findIndex(
					(existingItem: LineItemsStore) =>
						existingItem.id === item.merchandise.id,
				);

				// If an item with the same ID exists, update its quantity
				if (existingIndex !== -1) {
					const itemExisting = items[existingIndex];
					if (!itemExisting) {
						throw new Error('items is not defined');
					}
					itemExisting.quantity = item.quantity;
				} else {
					// Otherwise, construct a new item object and push it into the items array
					const obj: LineItemsStore = {
						attributes: item.attributes,
						compareAtPrice: item.merchandise.compareAtPriceV2
							? item.merchandise.compareAtPriceV2.amount
							: '',
						id: item.merchandise.id,
						brand: item.merchandise.product.retailer_brand,
						imageSrc: item.merchandise.image.src,
						options: item.merchandise.selectedOptions,
						price: item.merchandise.priceV2.amount,
						productTitle: item.merchandise.product.title,
						quantity: item.quantity,
						vendor: item.merchandise.product.vendor,
						lineId: item.id,
						// new_price: item.new_price,
						new_price: item.new_price ?? null,
						productId: item.merchandise.product.id,
						productHandle: item.merchandise.product.handle,
						tags: item.merchandise.product.tags,
						isLoading: false,
						quantityAvailable: item.merchandise.quantityAvailable,
						shippingDate: '',
						weight:
							checkoutLineItemResponse.value.length > 0
								? checkoutLineItemResponse.value?.find(
										(it) => it.id === item.merchandise.id,
									)?.weight!
								: 0,
						productAttributes: item.merchandise.product.attributes,
						node_l1: item.merchandise.product.node_l1,
						node_l2: item.merchandise.product.node_l2,
						node_l3: item.merchandise.product.node_l3,
						node_l4: item.merchandise.product.node_l4,
					};
					if (item.quantity > 0) items.push(obj);
					else emptyItems.push(obj);
				}
			}

			lineItems.value = items;
			outOfStockLineItems.value = emptyItems;
		}

		// If we have items in localCartData call mergeCart, else there is no need to call it
		// if (this.localCartData.length !== 0) await this.mergeCart();
		// Append Shipping date in each Items
		// await this.getItemShippingDate();
		await getShippingDates();
	};
	const variantId = ref<string>('');
	const { data: getShippingDate, refetch } =
		useShippingDatesEstimator(variantId);

	const getShippingDates = async () => {
		for (const [index, item] of lineItems.value.entries()) {
			const itemIdSplit = item.id.split('/').pop();
			if (!itemIdSplit) {
				throw new Error('Variant id undefined');
			}

			variantId.value = itemIdSplit;
			await refetch();
			const getShippingDateValue = getShippingDate.value;

			if (getShippingDateValue) {
				const shippingDate = getShippingDateValue.handling_date;
				const lineItem = lineItems.value;
				const lineItemm = lineItem[index];
				if (lineItemm) {
					lineItemm.shippingDate = shippingDate;
					lineItems.value[index] = lineItemm;
				}
			}
		}
	};

	const createCart = async () => {
		createCartMutation(`logan_id=${loganId.value}`, {
			onSuccess: (data) => {
				cartId.value = data.data.id;
				queryClient.setQueryData(['cartId', cartId], data);
				persist();
			},
		});
	};

	const removeCartItem = async (index: number, outOfStock: boolean = false) => {
		let lineId: string | string[];

		const lineItemsValue = outOfStock
			? outOfStockLineItems.value
			: lineItems.value;
		if (!lineItemsValue) {
			throw new Error('lineItemsValue undefined in removeCartItem');
		}

		const item = lineItemsValue[index];
		if (!item) {
			throw new Error('lineItemsValue[index] undefined in removeCartItem');
		} else {
			lineId = item.lineId;
		}

		const body = {
			cart_id: cartId.value,
			line_ids: lineId,
			customer_email: customerEmail.value,
			logan_id: loganId.value,
		};
		removeCartItemMutation(body, {
			onSuccess: (data) => {
				queryClient.setQueryData(['cartId', cartId], data);
			},
		});
	};

	const removeAllCartItems = () => {
		let lineId: string | string[];

		const lineItemsValue = lineItems.value;
		if (!lineItemsValue) {
			throw new Error('lineItemsValue undefined in removeAllCartItems');
		}
		// if we have more than 1 item, map the line items and get all line ids
		if (getItemsCount.value > 1) {
			const lineIds = lineItemsValue?.map((it: any) => it.lineId);
			// Check if all lineIds are the same
			if (lineIds?.every((id: any) => id === lineIds[0])) {
				lineId = lineIds[0]; // Set lineId to the single lineId
			} else {
				lineId = lineIds; // Set lineId to the list of lineIds
			}
		}
		// else only get the 0th element
		else {
			const item = lineItemsValue[0];
			if (!item) {
				throw new Error('Item undefined in removeAllCartItems');
			}
			lineId = item.lineId;
		}
		const body = {
			cart_id: cartId.value,
			line_ids: lineId,
			customer_email: customerEmail.value,
			logan_id: loganId.value,
		};
		removeCartItemMutation(body, {
			onSuccess: (data) => {
				queryClient.setQueryData(['cartId', cartId], data);
			},
		});
	};

	const updateCheckoutItemQuantity = async (lineItem: {
		email: string | null;
		lineItem: {
			customAttributes: {
				key: string;
				value: string;
			}[];
			quantity: number;
			variantId: string;
		};
	}) => {
		updateCheckoutItemMutation(lineItem, {
			onSuccess: (data) => {
				const filtered = lineItems.value.filter(
					(item) => item.id !== lineItem.lineItem.variantId,
				);
				if (lineItem.lineItem.quantity === 0) {
					checkoutLineItemResponse.value = [...filtered];
				} else {
					checkoutLineItemResponse.value = [
						data.data.lineItem.variantsLis[0],
						...filtered,
					];
				}
			},
		});
	};

	const updateQuantity = async (
		index: number,
		updatedQuantity: number,
		fromAddToBag: boolean = false,
	) => {
		const lineItemsValue = lineItems.value;
		let lines = {};
		if (!lineItemsValue) {
			throw new Error('lineItemsValue undefined in updateQuantity');
		} else {
			if (fromAddToBag) {
				updateCartFromPdpLoading.value = true;
			}
			const item = lineItemsValue[index];
			if (!item) {
				throw new Error('lineItemsValue[index] undefined in updateQuantity');
			} else {
				lines = [
					{
						id: item.lineId,
						merchandiseId: item.id,
						quantity: updatedQuantity,
						attributes: item.attributes,
					},
				];
			}
		}
		const body = {
			cart_id: cartId.value,
			lines: lines,
			customer_email: customerEmail.value,
			logan_id: loganId.value,
		};
		return await updateCartItemMutation(body, {
			onSuccess: (data) => {
				queryClient.setQueryData(['cartId', cartId], data);
				if (fromAddToBag) {
					updateCartFromPdpLoading.value = false;
					triggerAddedToBag();
				}
			},
			onError: () => {
				updateCartFromPdpLoading.value = false;
			},
		});
	};
	const handleAddToCart = async (
		selectedVariantData: ProductVariant,
		quantity: number,
		attributes: { key: string; value: string }[] | [],
	) => {
		// TODO:
		// 1- Check for custom size input -- done
		// 2- Adding _discountedValue to attributes of item for B2B discount. -- pending
		// 3- Increasing quantity on add to cart click -- done
		// if we have items in cart, quantity is to be updated -- done
		let itemExists = false;
		let index = 0;
		let existingQuantity = 0;
		const lineItemsValue = lineItems.value;
		if (!lineItemsValue) {
			throw new Error('Error');
		}
		const variantId =
			'gid://shopify/ProductVariant/' + selectedVariantData.shopify_id;
		if (lineItemsValue.length !== 0) {
			for (const [idx, item] of lineItemsValue.entries()) {
				if (item.id === variantId) {
					itemExists = true;
					index = idx;
					existingQuantity = item.quantity;
					break;
				}
			}
			if (itemExists) {
				const updatedQuantity = existingQuantity + quantity;

				// Quantity checks
				// if user is increasing quntity and increased quantity is greater than max buyable quantity
				const item = lineItemsValue[index];
				if (!item) {
					throw new Error('error 2');
				}
				if (updatedQuantity > 5) {
					return 'Max buyable quantity reached.';
				}
				// if user is increasing quntity and increased quantity is greater than available items
				else if (updatedQuantity > item.quantityAvailable) {
					return 'Available quantity limit reached.';
				}

				// If we did not enter any of the above conditions, call update quantity
				return updateQuantity(index, updatedQuantity, true);
			}
		}
		return addToCart(variantId, quantity, attributes);
	};
	const addToCart = async (
		variantId: string,
		quantity: number,
		attributes: { key: string; value: string }[] | [],
	) => {
		const lines = [
			{
				merchandiseId: variantId,
				quantity: quantity,
				attributes: attributes,
			},
		];
		const body = {
			cart_id: cartId.value,
			lines: lines,
			customer_email: customerEmail.value,
			logan_id: loganId.value,
		};
		return await addToCartMutation(body, {
			onSuccess: (data) => {
				queryClient.setQueryData(['cartId', cartId], data);
				triggerAddedToBag();
			},
		});
	};

	// I will update these functions when integrating with logan

	// // this functions add them locally, will be used once logan is integrated
	// const addHvcAttributesToLineItems = () => {
	// 	// dummy variable
	// 	const lineItemsValue = lineItems.value;
	// 	if (!lineItemsValue) {
	// 		throw new Error(
	// 			'lineItemsValue undefined in addHvcAttributesToLineItems',
	// 		);
	// 	}
	// 	if (CustomerStore.isHvc && lineItemsValue.length !== 0) {
	// 		const modifiedLineItems = lineItemsValue.map((item) => {
	// 			if (Object.keys(item).includes('new_price')) {
	// 				if (!item.attributes) {
	// 					item.attributes = [];
	// 				}
	// 				const existingDiscountIndex = item.attributes.findIndex(
	// 					(object: { key: string }) => object.key === '_laamDiscount',
	// 				);
	// 				if (existingDiscountIndex !== -1) {
	// 					return item; // Return the unchanged item if _laamDiscount attribute already exists
	// 				}
	// 				const newPrice = item.new_price;
	// 				if (!newPrice) {
	// 					throw new Error(
	// 						'newPrice is undefined in addHvcAttributesToLineItems',
	// 					);
	// 				}

	// 				const discountedValue =
	// 					((parseFloat(item.merchandise.priceV2.amount) - newPrice) /
	// 						parseFloat(item.merchandise.priceV2.amount)) *
	// 					100;

	// 				const discountObject = {
	// 					key: '_laamDiscount',
	// 					value: discountedValue.toFixed(2),
	// 				};

	// 				// Create a new item object with updated attributes
	// 				return {
	// 					...item,
	// 					attributes: [...item.attributes, discountObject], // Append the discountObject to the attributes array
	// 				};
	// 			} else {
	// 				return item; // Return the unchanged item if it doesn't have new_price
	// 			}
	// 		});
	// 		const _lines = [];
	// 		for (const item of modifiedLineItems) {
	// 			let _obj = {};
	// 			_obj = {
	// 				id: item.id,
	// 				merchandiseId: item.merchandise.id,
	// 				attributes: item.attributes,
	// 			};
	// 			_lines.push(_obj);
	// 		}
	// 		const body = {
	// 			cart_id: cartId.value,
	// 			lines: _lines,
	// 			customer_email: customerEmail.value,
	// 			logan_id: loganId.value,
	// 		};
	// 		updateCartItemMutation(body, {
	// 			onSuccess: (data) => {
	// 				queryClient.setQueryData(['cartId', cartId], data);
	// 				loading.value = false;
	// 			},
	// 		});
	// 	}
	// };

	// const updateHvcAttributes = (loggedIn: boolean) => {
	// 	if (loggedIn) {
	// 		addHvcAttributesToLineItems();
	// 	} else {
	// 		const lineItemsValue = lineItems.value;
	// 		if (!lineItemsValue) {
	// 			throw new Error('lineItemsValue undefined in updateHvcAttributes');
	// 		}
	// 		const modifiedLineItems = lineItemsValue.map((item) => {
	// 			const indexOfObject = item.attributes.findIndex(
	// 				(object: { key: string; value: string }) => {
	// 					return object.key === '_laamDiscount';
	// 				},
	// 			);
	// 			if (indexOfObject !== -1) {
	// 				// Create a new array without the item to be removed
	// 				return {
	// 					...item,
	// 					attributes: item.attributes.filter(
	// 						(object: { key: string; value: string }) =>
	// 							object.key !== '_laamDiscount',
	// 					),
	// 				};
	// 			}
	// 			return item;
	// 		});
	// 		const _lines = [];
	// 		for (const item of modifiedLineItems) {
	// 			let _obj = {};
	// 			_obj = {
	// 				id: item.id,
	// 				merchandiseId: item.merchandise.id,
	// 				attributes: item.attributes,
	// 			};
	// 			_lines.push(_obj);
	// 		}
	// 		const body = {
	// 			cart_id: cartId.value,
	// 			lines: _lines,
	// 			customer_email: customerEmail.value,
	// 			logan_id: loganId.value,
	// 		};
	// 		updateCartItemMutation(body, {
	// 			onSuccess: (data) => {
	// 				queryClient.setQueryData(['cartId', cartId], data);
	// 				loading.value = false;
	// 			},
	// 		});
	// 	}
	// };
	const triggerAddedToBag = () => {
		showAddedToBag.value = true;
		addedToBagTimeoutId.value = setTimeout(() => {
			showAddedToBag.value = false;
			triggerViewBag();
		}, 3000);
	};

	const triggerViewBag = () => {
		showViewBag.value = true;
		viewBagTimeoutId.value = setTimeout(() => {
			showViewBag.value = false;
		}, 3000);
	};

	return {
		cartId,
		cartIdLocal,
		lineItems,
		checkoutLineItemResponse,
		cart,
		loading,
		loganId,
		cartEmpty,
		getItemsCount,
		totalAmount,
		nativeCartAmount,
		checkoutUrl,
		totalTax,
		totalDuty,
		outOfStockLineItems,
		showAddedToBag,
		showViewBag,
		viewBagTimeoutId,
		addedToBagTimeoutId,
		totalVariantCount,
		updateLoading,
		checkoutCartItemsDiff,

		updateCheckoutItemQuantity,
		createCart,
		getCartById,
		removeCartItem,
		removeAllCartItems,
		updateQuantity,
		addToCart,
		handleAddToCart,

		// updateHvcAttributes,
	};
});

export const useCartStoreTest = defineStore('cart', () => {
	const cartId = ref('');
	const lineItems = ref<CartItemMockSchema[]>([]);
	const outOfStockLineItems = ref([]);
	const totalAmount = ref(65365);
	const cartEmpty = ref(false);
	const cart = ref({
		totalQuantity: 5,
		cost: {
			checkoutChargeAmount: {
				amount: '65362.5',
				currencyCode: 'PKR',
			},
			subtotalAmount: { amount: '65362.5', currencyCode: 'PKR' },
			totalAmount: { amount: 65365, currencyCode: 'PKR' },
			totalDutyAmount: null,
			totalTaxAmount: null,
		},
	});
	const getItemsCount = computed(() => {
		const cartValue = cart.value;
		if (!cartValue) {
			return 0;
		}
		return cartValue.totalQuantity;
	});

	const loading = computed(() => {
		return false;
	});
	return {
		cartId,
		lineItems,
		cart,
		loading,
		getItemsCount,
		outOfStockLineItems,
		totalAmount,
		cartEmpty,
	};
});
