<script setup lang="ts">
import { Toaster } from '@laam/ui/base';
import { useCartStore } from '~/stores/cart.ts';
import { useCountryCode } from '~/stores/country';
import type { ShippingConfig, ShippingDetails } from '~/types/statsig';

const CartStore = useCartStore();

const countryCode = useCountryCode().countryCode;
const shippingConfig = ref<ShippingConfig | null>(null);
provide('shippingConfig', shippingConfig);

const getFreeShippingFeature = () => {
	const statsigConfig = window.$statsig?.getConfig(
		'free_shipping_feature',
	) as unknown as Ref<ShippingDetails>;
	if (!statsigConfig?.value) {
		throw new Error('free_shipping_feature config is not defined');
	}
	shippingConfig.value = statsigConfig?.value.countries[countryCode]!;
};

onMounted(async () => {
	if (CartStore.cartId === '') {
		await CartStore.createCart();
	} else {
		await CartStore.getCartById();
	}
	callWithRetries(() => getFreeShippingFeature());
});

definePageMeta({
	layout: false,
});
</script>

<template>
	<div>
		<Toaster :show-close="false"></Toaster>
		<slot />
	</div>
</template>
