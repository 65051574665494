<script setup lang="ts">
import { ConfigProvider } from 'radix-vue';
import { useRoute } from 'vue-router';
import { useRuntimeConfig } from 'nuxt/app';
import { v4 as uuidv4 } from 'uuid';
import { FBConversion } from '~/utils/fbConversion.ts';
import { useGlobalStore } from './stores/global';
import seoSchema from '~/utils/seoSchema.json';
import { storeToRefs } from 'pinia';
import { useCountryCode } from './stores/country';
import { StatsigSingleton } from '@laam/statsig-client';
const useIdFunction = () => useId();
const { $eventClient, $cathodeClient } = useNuxtApp();
const route = useRoute();

const url = useRequestURL();

const GlobalStore = useGlobalStore();
const CountryCodeStore = useCountryCode();
const { setCountryCode } = CountryCodeStore;

const { store } = storeToRefs(GlobalStore);
const checkoutLocalization = ref<boolean>(true);

// add meta tags only on server
if (!process.client) {
	await GlobalStore.fetchStoreConfigAndGlobalSections();
	const headers = useRequestHeaders();
	GlobalStore.setDomain();

	const geoLocation = headers?.['x-client-geo-location'] ?? null;
	let countryCode = '';
	if (geoLocation) {
		countryCode = extractCountryCode(geoLocation)!;
	} else {
		countryCode = 'PK';
	}
	setCountryCode(countryCode);
}

// scroll state
const visible = ref(true);
const scrollPosition = ref(0);
const scrollBuffer = 72;

const toggleVisibility = () => {
	const currentScrollPos = document.documentElement.scrollTop;
	if (currentScrollPos - scrollPosition.value > scrollBuffer) {
		visible.value = false;
		scrollPosition.value = currentScrollPos;
	} else if (scrollPosition.value - currentScrollPos > scrollBuffer) {
		visible.value = true;
		scrollPosition.value = currentScrollPos;
	}
};

const testUrls = ['localhost', 'dev', 'test', 'shop', 'local', 'dev-v2'];
if (testUrls.some((testUrl) => url.host.includes(testUrl))) {
	useHead({
		meta: [
			{
				name: 'robots',
				content: 'noindex',
			},
		],
	});
}

const gsvTags = ['dev', 'test', 'shop', 'local', 'dev-v2', 'laam'];
if (gsvTags.some((gsvTag) => url.host.includes(gsvTag))) {
	useHead({
		meta: [
			{
				name: 'google-site-verification',
				content: 'WzMytRFwM90Jj60jTuyFKOFWuP8jUxuf1PGCbTou3tQ',
			},
		],
	});
}

const newFilterUi = ref(false);
const pdpNewDesign = ref(false);
const AddToBagCta = ref(false);

const deliveryOptionsExp = ref(false);
const getDeliveryOptionsExp = () => {
	try {
		const deliveryOptions = StatsigSingleton.getExperiment(
			'delivery-to-options',
		);
		deliveryOptionsExp.value = deliveryOptions.get('Allowed', false);
	} catch (e) {
		throw new Error('Delivery options experiment statsig failed');
	}
};
onMounted(() => {
	getDeliveryOptionsExp();
	newFilterUi.value = StatsigSingleton.getExperiment('reverse-filter-ui').get(
		'newFilterUi',
		false,
	);

	pdpNewDesign.value = StatsigSingleton.getExperiment('pdp-redesign').get(
		'pdpNewDesign',
		false,
	);

	AddToBagCta.value = StatsigSingleton.getExperiment('pdp-redesign').get(
		'AddToBagCta',
		false,
	);

	window.addEventListener('scroll', toggleVisibility);
	$eventClient.sendEvent('session-start', {
		location: window.location.href,
		referrer: document.referrer,
	});

	$eventClient.sendEvent('page-view', {
		location: window.location.href,
		referrer: document.referrer,
	});
	logPagePerformance();
	setUserUUID();

	window.addEventListener('beforeunload', () => {
		$cathodeClient.fireAtWindowClose();
	});
});

onUnmounted(() => {
	window.removeEventListener('scroll', toggleVisibility);
	window.removeEventListener('beforeunload', () => {
		$cathodeClient.fireAtWindowClose();
	});
});

watch(
	() => route.path, // watch for route changes
	(newValue, oldValue) => {
		if (
			newValue === '/profile' &&
			oldValue !== '/orders' &&
			oldValue !== '/wishlist' &&
			oldValue !== '/address-book'
		) {
			localStorage.setItem('beforeProfile', oldValue);
		}
		$eventClient.sendEvent('page-view', {
			location: window.location.href,
			referrer: window.location.origin + oldValue,
		});

		FBConversion.apiCall({
			event_name: 'page-view',
		});
	},
);

watch(
	() => route.fullPath,
	() => {
		// preserve utm meta
		if (import.meta.server) {
			return;
		}
		if (sessionStorage.getItem('campaign')) {
			const location = window.location.href;
			const utms = sessionStorage.getItem('campaign')!.split('&');
			const searchParams = new URL(location).searchParams;
			utms.forEach((utm) => {
				const [key, value] = utm.split('=');
				if (!searchParams.has(key!)) {
					searchParams.append(key!, value!);
				}
			});
			history.replaceState(
				{},
				'',
				`${window.location.pathname}?${searchParams.toString()}`,
			);
		}
	},
);

provide('headerVisible', visible);
provide('checkoutLocalization', checkoutLocalization);
provide('newFilterUi', newFilterUi);
provide('pdpNewDesign', pdpNewDesign);
provide('AddToBagCta', AddToBagCta);
provide('deliveryOptionsExp', deliveryOptionsExp);
const storeMetadata = GlobalStore.store.meta_data;
defineOgImage({
	url:
		GlobalStore.storeType === 'OCTANE'
			? storeMetadata?.og_image
				? storeMetadata.og_image
				: storeMetadata?.logo
			: 'https://cdn.shopify.com/s/files/1/2337/7003/files/link-thumbnail-3.jpg?v=1722003616',
});

const setUserUUID = () => {
	const user_uuid = useCookie('user_uuid', { maxAge: 86400 * 14 });
	if (!user_uuid.value) {
		user_uuid.value = uuidv4();
	}
};
if (useRuntimeConfig().public.env === 'production' && url.host === 'laam.pk') {
	useHead({
		script: [
			{
				type: 'application/ld+json',
				children: {
					'@type': 'Organization',
					name: seoSchema.name,
					url: seoSchema.url,
					logo: '',
					description: seoSchema.description,
					telephone: seoSchema.telephone,
					foundingDate: seoSchema.foundingDate,
					sameAs: [
						seoSchema.sameAsLinkedin,
						seoSchema.sameAsFacebook,
						seoSchema.sameAsInstagram,
						seoSchema.sameAsYoutube,
					],
					image: {
						'@type': 'ImageObject',
						url: '',
					},
					founders: [
						{
							'@type': 'Person',
							name: 'Arif Iqbal',
						},
						{
							'@type': 'Person',
							name: 'Amir Iqbal',
						},
					],
					contactPoint: {
						'@type': 'ContactPoint',
						contactType: seoSchema.contactType,
						url: '',
						email: seoSchema.email,
					},
					address: {
						'@type': 'PostalAddress',
						addressLocality: seoSchema.addressLocality,
						addressRegion: seoSchema.addressRegion,
						postalCode: seoSchema.postalCode,
						addressCountry: '',
					},
					country: {
						'@type': 'Country',
						name: 'Pakistan',
					},
				},
			},
		],
	});
}

// change favicon for octane stores
const favicon = (store.value?.meta_data as { favicon: string })?.favicon;
if (favicon) {
	useHead({
		link: [
			{
				rel: 'icon',
				href: favicon !== '' ? favicon : store.value.meta_data?.logo,
			},
		],
	});
}

const layout = computed(() => {
	const storeType = GlobalStore.store?.store_type;
	if (storeType === 'OCTANE') {
		return 'octane';
	} else return 'default';
});
</script>

<template>
	<ConfigProvider :use-id="useIdFunction">
		<NuxtLoadingIndicator />
		<StatsigExperimentProvider>
			<NuxtLayout :name="layout">
				<NuxtPage keep-alive />
			</NuxtLayout>
		</StatsigExperimentProvider>
	</ConfigProvider>
</template>

<style>
@import '@laam/ui/main.css';
</style>
