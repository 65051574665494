export type NewArrivalsExperiment = {
	new_arrival_period: string;
	is_active: boolean;
	min_sellable_products: number;
	brand_created_at: string;
	drop_created_at: string;
};

export const newArrivalExperimentInjectionKey = Symbol(
	'newArrivalExperiment',
) as InjectionKey<Ref<NewArrivalsExperiment | undefined>>;

export const useStatsigExperiment = () => {
	const newArrivalExperiment = inject(newArrivalExperimentInjectionKey);

	return { newArrivalExperiment };
};
