import {
	type CategoriesResponse,
	type BrandsResponse,
	type CategoryTreeResponse,
	type DropsResponse,
} from './validations/sinclair';
import {
	type StaticInputData,
	type LinkNode,
	type StaticInputFormSchema,
} from './validations/types';

export * from './validations/sinclair';
export * from './validations/types';
export * from './validations/zod';

export const findInTree = (
	id: number,
	tree: CategoryTreeResponse[],
): CategoryTreeResponse | null => {
	for (const node of tree) {
		if (node.id === id) {
			return node;
		}
		if (node.children) {
			const found = findInTree(id, node.children);
			if (found) {
				return found;
			}
		}
	}
	return null;
};

export const removeNodesWithNoProducts = (tree: CategoryTreeResponse) => {
	const children = tree.children;
	if (tree.children) {
		return {
			...tree,
			children: children?.filter((node) => {
				if (node.product_count === 0) {
					return false;
				}
				return true;
			}),
		};
	}
	return tree;
};

export const initializeActiveTree = (
	categories: CategoryTreeResponse,
	selectedNodeId?: number,
) => {
	if (!selectedNodeId) {
		return [categories];
	}
	// find the node in the tree and return path as array
	const traverseTree = (
		nodeId: number,
		tree: CategoryTreeResponse,
	): CategoryTreeResponse[] => {
		if (tree.id === nodeId) {
			return [tree];
		} else if (tree.children) {
			for (const node of tree.children) {
				const result = traverseTree(nodeId, node);
				if (result.length) return [tree, ...result];
			}
		} else return [];
		return [];
	};
	try {
		const activeTree = traverseTree(selectedNodeId, categories);
		activeTree.pop();
		if (!activeTree.length) {
			console.log('Node not found');
			throw new Error('Node not found');
		}
		return activeTree.map((t) => removeNodesWithNoProducts(t));
	} catch (e) {
		return [categories];
	}
};

const baseObject = {
	name: '',
	href: '',
	children: [],
	entity: 'quick-adds',
};
interface CreateNavMenuItem {
	name: string;
	href: string;
	id: string;
	subtitle?: string;
}

export function isLinkNode(
	data: CategoriesResponse | LinkNode[],
): data is LinkNode[] {
	return data.every((item) => 'entity' in item);
}

export const createNavMenuItem = (payload: CreateNavMenuItem) => {
	return { ...baseObject, ...payload };
};

export const mapDropsToLinkNodes = (
	drops: DropsResponse,
	storeType: string,
): LinkNode[] => {
	return (Array.isArray(drops) ? drops : []).map((drop) => {
		return {
			entity: 'drops',
			href: `/drops/${drop.handle}`,
			name: drop.title,
			// subtitle: drop.brand! // FIXME: Please make this work
			product_count: drop.product_count,
			max_sale: storeType === 'OCTANE' ? 0 : drop.product_agg_sale_percentage,
			slotted: false, // FIXME: Please make this work
			handle: drop.handle,
			id: drop.drop_id,
			image: drop.v24_top_product_image,
			subtitle:
				storeType === 'OCTANE'
					? ''
					: (drop.brand || drop.brand_name) ?? drop.product_count + ' items',
		};
	});
};

export const mapBrandsToLinkNodes = (brands: BrandsResponse): LinkNode[] => {
	return (Array.isArray(brands) ? brands : []).map((brand) => {
		return {
			entity: 'brands',
			href: `/brands/${brand.handle}`,
			name: brand.name,
			product_count: brand.product_count,
			max_sale: brand.product_agg_sale_percentage,
			slotted: false, // FIXME: Please make this work
			handle: brand.handle,
			id: brand.id,
			image: brand.v24_top_product_image,
			subtitle: brand.product_count + ' items',
			logo: brand.logo,
		};
	});
};

export const getHref = (
	item: StaticInputFormSchema,
	category: CategoriesResponse[number],
) => {
	const queryString = item.href ? item.href.split('?')[1] : '';
	const categoryQueryString = `/nodes/${category.handle!}`;
	return `${categoryQueryString}${queryString ? `?${queryString}` : ''}`;
};

export const mapCategoriesToLinkNodes = (
	categories: CategoriesResponse,
	input: StaticInputData,
): LinkNode[] => {
	// asserts that this is a StaticInputData, will always evaluate to false
	if (!('items' in input)) {
		return [];
	}
	return (Array.isArray(categories) ? categories : []).map(
		(category, index) => {
			return {
				entity: 'categories',
				href: getHref(input.items[index]!, category),
				name: input.items[index]!.name
					? input.items[index]!.name!
					: category.title,
				product_count: category.product_count,
				id: category.id,
				image: input.items[index]!.image
					? input.items[index]!.image!
					: category.v24_top_product_image,
				fallback_image: category.static_logo,
			};
		},
	);
};
