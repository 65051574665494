import countries from '../utils/countries.json';

export const sortedCountries = (() => {
	const { top, remaining } = Object.entries(countries).reduce(
		(acc, [key, value], index) => {
			if (index < 8) {
				acc.top.push([key, value]);
			} else {
				acc.remaining.push([key, value]);
			}
			return acc;
		},
		{
			top: [] as [string, (typeof countries)[keyof typeof countries]][],
			remaining: [] as [string, (typeof countries)[keyof typeof countries]][],
		},
	);
	// Combine the top countries with sorted remaining countries and map to array format
	return top
		.concat(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			remaining.sort(([__, valueA], [_, valueB]) =>
				valueA.name.localeCompare(valueB.name),
			),
		)
		.map(([key, value]) => ({
			code: key,
			...value,
		}));
})();
