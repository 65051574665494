<script setup lang="ts">
import { type Products } from '~/data/products';
import ProductListSkeleton from './Skeleton.vue';
import type { InfiniteData } from '@tanstack/vue-query';
import type { WishlistDetailTransformed } from '~/data/wishlist';
import ButtonLoader from '../ButtonLoader.vue';
import { Checkbox, FormField } from '@laam/ui/base';
import { StatsigSingleton } from '@laam/statsig-client';
import { storeToRefs } from 'pinia';
import ProductCard from '../ProductCard.vue';
import { useInfiniteSroll } from '~/utils/infinite-scroll';
import NotFound from '~/icons/NotFound.vue';
interface ProductListProps {
	header?: {
		title: string;
		subtitle: string;
	};
	infiniteLoader: boolean;
	class?: string;
	data:
		| InfiniteData<Products>
		| InfiniteData<WishlistDetailTransformed>
		| undefined;
	isLoading: boolean;
	hasNextPage: boolean;
	fetchNextPage: () => void;
	isFetchingNextPage: boolean;
	scrollThreshold?: number;
}
const nonReactiveProps = defineProps<ProductListProps>();

const {
	isLoading,
	isFetchingNextPage,
	data,
	hasNextPage,
	fetchNextPage,
	infiniteLoader,
	header,
} = toRefs(nonReactiveProps);

const hasHeader = computed(() => !!header.value);

const notifyMeProduct = ref<{
	show: boolean;
	shopifyId: number;
	url: string;
	disabled: number[];
}>({
	show: false,
	shopifyId: 0,
	url: '',
	disabled: [],
});
provide('notifyMeProduct', notifyMeProduct);
const disableNotifyMe = (shopifyId: number) => {
	notifyMeProduct.value.disabled.push(shopifyId);
};

const valhallaExperiment = ref();
const dataSource = ref<{
	dataSource: string;
	clickedFrom: string[];
	version: string | undefined;
}>({
	dataSource: '',
	clickedFrom: [FeatureEnum.COLLECTION_LISTING],
	version: '',
});
const pdpQuickAddExperiment = ref(false);
const shippingDaysBadgeExp = ref(true);

onMounted(async () => {
	shippingDaysBadgeExp.value = StatsigSingleton.getExperiment(
		'delivery-days-badge',
	).get('showBadge', true);
	getValhallaExperiment();
	setSource();
	// if we are on mobile only then we will run this experiment
	if (isMobileView()) {
		const exp = StatsigSingleton.getExperiment('pdp-quickadd');
		pdpQuickAddExperiment.value = exp.value.Allowed as boolean;
	}
});

async function getValhallaExperiment() {
	const valhallaExperimentStatsig =
		StatsigSingleton.getExperiment('valhalla_fetch');
	const valhallaSlottingExp = StatsigSingleton.getExperiment(
		'valhalla-products-slotting',
	);
	let slotting = '';
	let slotPerc = '';
	let statsigStableId = '';
	const functionScope = 'global';

	if (valhallaSlottingExp) {
		slotting = valhallaSlottingExp.get('Slotting', '');
		slotPerc = valhallaSlottingExp.get('Slot-Percent', '');
	}

	// Check if valhallaExperimentStatsig exists and is a valid object
	if (
		valhallaExperimentStatsig &&
		valhallaExperimentStatsig.get('isActive', false)
	) {
		// personalisation
		if (
			valhallaExperimentStatsig.get('version', '').includes('personalisation')
		) {
			statsigStableId = StatsigSingleton.getStableID();
		}
		// experiment for multiple scoring functions
		valhallaExperiment.value = {
			isActive: valhallaExperimentStatsig.get('isActive', false),
			version: valhallaExperimentStatsig.get('version', 'v24'),
			slotting: slotting,
			slotPerc: slotPerc,
			sessionId: statsigStableId,
			functionScope: valhallaExperimentStatsig.get('functionScope', 'global'),
		};
	} else {
		// Handle the case when the experiment configuration is not available
		// assign default v8 ???? rameen plis
		valhallaExperiment.value = {
			isActive: 'true',
			version: 'v24',
			slotting: slotting,
			slotPerc: slotPerc,
			sessionId: statsigStableId,
			functionScope: functionScope,
		};
	}

	return valhallaExperiment.value;
}

function setSource() {
	if (valhallaExperiment.value.isActive == 'false') {
		dataSource.value.dataSource = DataSourceEnum.STOREFRONT;
		dataSource.value.version = 'storefront';
	} else {
		dataSource.value.dataSource = DataSourceEnum.VALHALLA;
		dataSource.value.version = valhallaExperiment.value.version;
	}
}

const editMode = inject('editMode', ref(false));

const logan = useLoganStore();
const { isSignedIn, isHvc: isLoganHvc } = storeToRefs(logan);
const isHvc = computed(() => isSignedIn.value && isLoganHvc.value);

const handleNextPage = async () => {
	if (hasNextPage.value && !isFetchingNextPage.value) {
		fetchNextPage.value();
	}
};
provide('pdpQuickAddExperiment', pdpQuickAddExperiment);
provide('shippingDaysBadgeExp', shippingDaysBadgeExp);
useInfiniteSroll(handleNextPage, nonReactiveProps.scrollThreshold);
</script>

<template>
	<div
		:class="{
			'lg:p-3xl': hasHeader,
			[nonReactiveProps.class!]: !!nonReactiveProps.class,
		}"
	>
		<ProductCardNotifyMe
			v-if="notifyMeProduct.show"
			@disable-notify-me="disableNotifyMe"
		/>
		<template v-if="isLoading">
			<ProductListSkeleton />
		</template>
		<template
			v-else-if="
				!isLoading &&
				(!data || !data.pages.length || !data.pages[0]!.products.length)
			"
		>
			<div class="p-3xl flex items-center justify-center mt-8xl mb-8xl">
				<div class="text-center">
					<div class="flex justify-center">
						<NotFound />
					</div>
					<span class="text-md font-semibold">
						Oops! we couldn't find anything.
					</span>
					<br />
					<span class="text-sm text-gray-600">
						No results for your search. Try another term to find what you’re
						looking for!
					</span>
				</div>
			</div>
		</template>
		<template v-else>
			<div
				v-if="hasHeader"
				class="product_list_header flex flex-row lg:flex-col gap-xs justify-between lg:items-start items-center lg:pb-3xl lg:pt-0 lg:px-0 pt-3xl pb-0 px-3xl"
			>
				<h3 class="text-md font-semibold text-gray-800 truncate text-nowrap">
					{{ header!.title }}
				</h3>
				<h4 v-if="header?.subtitle" class="text-sm text-gray-600 text-nowrap">
					{{ header!.subtitle }}
				</h4>
			</div>
			<div
				class="product_list_container sm:grid-cols-3 lg:grid-cols-4 p-3xl lg:p-0 gap-3xl lg:gap-7xl grid grid-cols-2"
			>
				<template v-for="(page, idx) in data!.pages" :key="idx">
					<template v-for="product in page.products" :key="product.shopify_id">
						<!-- FIXME: type error for data prop -->
						<ProductCard
							:data="product"
							:horizontal-index="idx"
							:data-source="dataSource"
							page="PLP"
							:is-hvc="isHvc"
							:impression-debounce-time="
								useRuntimeConfig().public.impressionDebounceTime
							"
							:show-new-tag="product.tags.includes('Recently-Sellable')"
						>
							<template #top-right>
								<WishlistButton
									v-if="!editMode"
									:product-id="product.shopify_id"
									:image-url="product.image.src"
									:image-alt="product.image.alt"
								/>

								<FormField
									v-else
									v-slot="{ value, handleChange }"
									name="selectedProducts"
									type="checkbox"
									:value="product.shopify_id"
								>
									<div
										class="right-none top-none p-md select_trigger absolute bg-transparent"
										@click="(e) => e.preventDefault()"
									>
										<Checkbox
											:checked="value.includes(product.shopify_id)"
											@update:checked="handleChange"
										/>
									</div>
								</FormField>
							</template>
						</ProductCard>
					</template>
				</template>
			</div>
			<div
				v-if="!isLoading && infiniteLoader"
				class="infinite_loader p-lg bg-neutral-50 flex items-center justify-center"
			>
				<ButtonLoader v-if="isFetchingNextPage" />
				<span v-else>No more products</span>
			</div>
		</template>
	</div>
</template>
