<script setup lang="ts">
import CategoriesDesktop from './CategoriesDesktop.vue';
import BrandsDesktop from './BrandsDesktop.vue';
import Logan from '~/components/logan/Logan.vue';

import { LaamIcon } from '@laam/ui/icons';

import { useRoute } from 'vue-router';

import CurrencySelector from '~/components/logan/CurrencySelector.vue';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import ShareDialog from '~/components/product-detail/ShareDialog.vue';
import CartButton from '~/components/CartButton.vue';
import TopBanner from '~/components/TopBanner.vue';
import { isRewardsEnabled } from '~/utils/featureFlags.ts';
import { useLoganStore } from '~/stores/logan.ts';
import SearchHeader from '~/components/search/SearchHeader.vue';
import { Button } from '@laam/ui/base';
import DeliveryOptions from '~/components/delivery-options/DeliveryOptions.vue';

const LoganStore = useLoganStore();
const { isSignedIn, isHvc, firstName, lastName, email } =
	storeToRefs(LoganStore);

const route = useRoute();

const pathName = computed(() => route.path);

const { setCurrency } = useCurrency();
const schema = toTypedSchema(
	z.object({
		currency: z.string(),
	}),
);
const { values } = useForm({
	validationSchema: schema,
});

const rewardsEnabled = ref<boolean>(false);
const pdpNewDesign = inject('pdpNewDesign') as Ref<boolean>;
const deliveryOptionsExp = inject('deliveryOptionsExp') as Ref<boolean>;
watch(
	isSignedIn,
	() => {
		rewardsEnabled.value = isRewardsEnabled() ?? false;
	},
	{
		immediate: true,
	},
);

watch(values, (value) => {
	if (value.currency) {
		const country = value.currency as unknown;
		setCurrency(country as CURRENCY);
	}
});
const showIndependenceLogo = ref(false);

onMounted(() => {
	showIndependenceLogo.value = isIndependenceDayGateEnabled() ?? false;
});

const selectedTab = ref(route.path.includes('wishlist') ? 'wishlist' : 'home');
const setSelectedTab = (tab: string) => {
	selectedTab.value = tab;
};
provide('setSelectedTab', setSelectedTab);
const showNov11Sale = inject('showNov11Sale') as boolean;
</script>

<template>
	<div>
		<div class="relative">
			<div
				class="lg:block sticky top-0 left-0 right-0 z-30 hidden bg-white border-b"
			>
				<ClientOnly>
					<TopBanner />
				</ClientOnly>
				<div class="header--desktop py-3xl flex items-center w-full z-30">
					<NuxtLink to="/" class="block w-[250px] pl-7xl">
						<NuxtImg v-if="showNov11Sale" src="/nov_11_sale.gif" />
						<LaamIcon v-else variant="laam_logo_complete" />
					</NuxtLink>

					<div class="flex !max-w-[1032px] mx-auto px-10xl w-full gap-[76px]">
						<SearchHeader />
						<div v-if="pathName !== '/search'" class="gap-md flex relative">
							<DeliveryOptions v-if="deliveryOptionsExp" />

							<ShareDialog
								v-if="
									!pdpNewDesign &&
									pathName !== '/' &&
									pathName !== '/cart' &&
									pathName !== '/wishlist' &&
									pathName !== '/rewards'
								"
							/>

							<ShareDialogV2
								v-if="
									pdpNewDesign &&
									pathName !== '/' &&
									pathName !== '/cart' &&
									pathName !== '/wishlist' &&
									pathName !== '/rewards'
								"
							/>

							<CartButton />
						</div>
					</div>
				</div>
			</div>

			<div class="flex">
				<div
					class="side-nav py-8xl bg-white px-3xl w-[250px] shrink-0 pl-7xl border-r sticky bottom-0 z-10 lg:flex flex-col justify-between hidden"
				>
					<div>
						<NuxtLink to="/" class="block w-full">
							<Button
								variant="tertiary"
								class="w-full text-md py-xl font-medium text-gray-800 capitalize hover:bg-gray-50 justify-start"
							>
								All
							</Button>
						</NuxtLink>
						<NuxtLink to="/new-arrivals" class="block w-full">
							<Button
								variant="tertiary"
								class="w-full text-md py-xl font-medium text-gray-800 capitalize hover:bg-gray-50 justify-start"
								:class="route.path === '/new-arrivals' ? 'bg-gray-50' : ''"
							>
								New Arrivals
							</Button>
						</NuxtLink>
						<CategoriesDesktop />

						<BrandsDesktop />
					</div>

					<div>
						<NuxtLink
							:to="isSignedIn ? '/orders' : ''"
							class="nav-button hover:bg-gray-50 hover:cursor-pointer"
							@click="
								() => {
									if (!isSignedIn) {
										LoganStore.setRequiresSignIn();
									}
								}
							"
						>
							<span class="nav-button-text"> Orders </span>
						</NuxtLink>

						<div
							v-if="!isSignedIn && rewardsEnabled"
							class="nav-button hover:bg-gray-50 cursor-pointer"
							@click="() => LoganStore.setRequiresSignIn()"
						>
							<span class="nav-button-text"> Rewards </span>
						</div>
						<NuxtLink
							v-else-if="isSignedIn && !isHvc && rewardsEnabled"
							to="/rewards"
							class="nav-button hover:bg-gray-50"
						>
							<span class="nav-button-text"> Rewards </span>
						</NuxtLink>

						<NuxtLink
							to="/wishlist"
							class="nav-button nav-button-brands hover:bg-gray-50"
						>
							<span class="nav-button-text"> Wishlist </span>
						</NuxtLink>
						<CurrencySelector v-if="!deliveryOptionsExp" />

						<NuxtLink
							to="https://sellers.laam.pk/login"
							target="_blank"
							class="nav-button nav-button-brands hover:bg-gray-50"
						>
							<span class="nav-button-text"> Become a Seller </span>
						</NuxtLink>
						<NuxtLink
							to="/help-center"
							class="nav-button nav-button-brands hover:bg-gray-50"
						>
							<span class="nav-button-text"> Help center </span>
						</NuxtLink>

						<NuxtLink v-if="isSignedIn" to="/profile">
							<div
								v-if="firstName && lastName"
								class="pt-7xl mt-7xl gap-xl px-md flex items-center border-t"
							>
								<div class="truncate">
									<p class="text-sm font-semibold text-gray-700">
										{{ `${firstName} ${lastName}` }}
									</p>
									<p class="text-sm text-gray-600">{{ email }}</p>
								</div>
							</div>
							<div
								v-else
								class="pt-7xl mt-7xl gap-xl px-md flex items-center border-t"
							>
								<Avatar size="sm" shape="circle">
									<AvatarImage src="" alt="User avatar" />
									<AvatarFallback> LU </AvatarFallback>
								</Avatar>
								<div>
									<span class="text-sm font-semibold text-gray-700">
										Laam User
									</span>
									<span class="text-sm text-gray-600">{{ email }}</span>
								</div>
							</div>
						</NuxtLink>
						<Logan v-else-if="!isSignedIn && !pathName.includes('/login')">
							<div
								class="pt-7xl gap-xl px-md flex items-center border-t"
								@click="LoganStore.requiresSignIn = false"
							>
								<p class="font-semibold text-gray-900 text-md">
									Sign In / Register
								</p>
							</div>
						</Logan>
					</div>
				</div>

				<div class="mb-[55px] grow shrink-0">
					<div class="max-w-[1032px] mx-auto z-10 px-10xl">
						<slot />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.nav-button {
	@apply py-xl px-xl w-full gap-xxs border-0 flex items-center justify-between;
}

.nav-button-text {
	@apply lg:!text-md font-medium !text-xxs lg:text-gray-800;
	text-transform: none;
}

.side-nav {
	height: calc(100vh - var(--laam-topbar-height));
	top: calc(var(--laam-topbar-height) + var(--laam-shipping-banner-height));
}
</style>
