import type { LineItemsResponse } from '~/types/Checkout';
import { isValidPhoneNumber } from 'libphonenumber-js';
import countries from '~/utils/countries.json';
import { USTAXEDSTATES } from '~/utils/enums.ts';
import { isOriginalProductPrice } from '~/utils/helpers.ts';
import { pkPhoneNumberRegex } from '~/utils/enums';
import { useGlobalStore } from '~/stores/global';
import postalCodes from 'postal-codes-js';
export const getFinalTotalCost = (
	subTotal: number,
	shippingPrice: number,
	tax: number,
	creditCardDiscount: number,
) => {
	if (shippingPrice >= 0) {
		const totalPrice =
			subTotal + shippingPrice + (tax ?? 0) - (creditCardDiscount ?? 0);
		return totalPrice;
	}
	return 0;
};

export const confirmOrderPayload = (
	success: boolean,
	shopifyId: number,
	requestId: string,
	creditCardDiscount: number,
	rewards: number,
	paymentType: string,
	clientSecret: string,
	finalTotal: number,
	chargedFinalTotal: number | null,
	hvc: boolean,
	rewardsEnabled: boolean,
	loganId: string | undefined,
	domain: string,
	currencyCode: string,
	redisKey: string,
	couponCodeDiscount: number,
) => {
	return {
		success: success,
		id: shopifyId,
		request_id: requestId,
		credit_card_discount: creditCardDiscount,
		rewards: rewards,
		payment_type: paymentType,
		payment_intent_id: clientSecret,
		total_price: finalTotal,
		is_hvc: hvc,
		is_reward_enabled: rewardsEnabled,
		card_discount_amount: 0,
		loganId: loganId,
		store_domain: domain,
		...(!!chargedFinalTotal && { charged_total_price: chargedFinalTotal }),
		...(!!currencyCode && { base_currency_code: currencyCode }),
		redis_key: redisKey,
		coupon_discount: couponCodeDiscount,
	};
};

export const getRewardsToBeRedeemed = (
	rewardsBalance: number,
	finalTotal: number,
) => {
	// if rewards are greater than final total
	// then rewards equivalent to final total wil be deducted

	if (rewardsBalance > finalTotal) {
		return finalTotal;
	}
	// else all rewards deducted
	return rewardsBalance;
};
export const validateShipping = (shippingPrice: number) => {
	if (
		shippingPrice >= 0 &&
		shippingPrice !== undefined &&
		shippingPrice !== null &&
		!Number.isNaN(shippingPrice) &&
		typeof shippingPrice === 'number'
	) {
		return true;
	}

	return false;
};

export const validateTax = (
	taxAmount: number,
	countryCode: string,
	province: string,
): boolean => {
	// Ensure taxAmount is a non-negative number
	if (taxAmount < 0) {
		return false;
	}

	// Ensure countryCode is valid string
	if (typeof countryCode !== 'string' || countryCode.trim() === '') {
		return false;
	}

	// If the country is not the US but taxAmount is not 0, return false
	if (countryCode !== 'US') {
		if (taxAmount !== 0) {
			return false;
		}
	}

	// If the country is the US, perform further checks
	if (countryCode === 'US') {
		// check if province is valid string
		if (typeof province !== 'string' || province.trim() === '') {
			return false;
		}
		// Ensure that USTAXEDSTATES is an array and contains valid states
		if (!Array.isArray(USTAXEDSTATES) || USTAXEDSTATES.length === 0) {
			throw new Error('USTAXEDSTATES is not properly defined.');
		}

		// If province is not a taxed state and taxAmount is not 0, return false
		if (!USTAXEDSTATES.includes(province) && taxAmount !== 0) {
			return false;
		}

		// If province is a taxed state and taxAmount is 0, return false
		if (
			USTAXEDSTATES.includes(province) &&
			(taxAmount == 0 || taxAmount === null || taxAmount === undefined)
		) {
			return false;
		}
	}

	return true;
};
export const validateFinalPrice = (
	finalTotal: number,
	subTotal: number,
	tax: number,
	shipping: number,
	rewardsEnabled: boolean,
	rewardsPrice: number,
	couponCodeDiscount: number | undefined,
) => {
	if (shipping < 0) return false;
	if (finalTotal > 0 || rewardsEnabled) {
		let calculatedTotal = parseFloat((subTotal + tax + shipping).toFixed(2));
		if (rewardsEnabled) {
			calculatedTotal -= rewardsPrice;
		}
		if (couponCodeDiscount && couponCodeDiscount > 0) {
			calculatedTotal -= couponCodeDiscount;
		}
		if (finalTotal.toFixed(2) === calculatedTotal.toFixed(2)) {
			return true;
		}
	}
	return false;
};

export const validatePhoneNumber = (
	countryCode: string,
	phoneNumber: string,
) => {
	const phNumber = phoneNumber?.trim();
	if (countryCode !== 'PK') {
		const phoneCode = countries[countryCode as keyof typeof countries].phone[0];
		const regex = /^\d*$/;
		const valid =
			isValidPhoneNumber(`+${phoneCode}${phNumber}`) && regex.test(phNumber);
		return valid;
	} else {
		const valid = transformPhoneNumber(phNumber);
		if (valid === null) {
			return false;
		}
		return true;
	}
};

export const transformPhoneNumber = (phoneNumber: string) => {
	let last10Digits: string;
	if (pkPhoneNumberRegex.test(phoneNumber)) {
		last10Digits = phoneNumber.slice(-10);
		return last10Digits;
	}
	return null;
};

export const getTrimmedPhoneNumber = (
	phoneNumber: string,
	countryCode: string,
) => {
	if (!phoneNumber) return '';
	return countryCode === 'PK'
		? transformPhoneNumber(phoneNumber.trim()) || ''
		: phoneNumber.trim();
};

export const validateNameFields = (userNameValues: string) => {
	const alphabetRegex = /^[A-Za-z\s]+$/;
	return alphabetRegex.test(userNameValues);
};

export const generatePurchaseEventData = (
	finalTotal: number,
	currency: string,
	paymentType: string,
	discount: number,
	tax: number,
	shipping: number,
	orderNumber: string,
	id: string,
) => {
	return {
		totalPrice: finalTotal,
		currency: currency,
		paymentType: paymentType,
		discount: discount,
		totalTax: tax,
		shipping: shipping,
		orderNumber: orderNumber,
		value: finalTotal,
		transaction_id: id,
	};
};

const validationScroll = (id: string) => {
	if (id === 'checkout_contact_information') {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	} else
		document
			.getElementById(id)
			?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
};

export const scrollToError = (keys: string[]) => {
	if (keys.length > 0) {
		if (
			keys.includes('contactInformation.email') ||
			keys.includes('contactInformation.phoneNumber')
		) {
			validationScroll('checkout_contact_information');
		} else if (
			keys.includes('shippingAddress.firstName') ||
			keys.includes('shippingAddress.lastName') ||
			keys.includes('shippingAddress.address1') ||
			keys.includes('shippingAddress.city') ||
			keys.includes('shippingAddress.zip')
		) {
			validationScroll('checkout_shipping_address');
		} else if (
			keys.includes('paymentMethod') ||
			keys.includes('xpayFormReady') ||
			keys.includes('stripeFormReady')
		) {
			validationScroll('checkout_payment_methods');
		} else if (keys.some((key) => key.includes('billingAddress'))) {
			validationScroll('checkout_billing_address');
		} else if (keys.includes('isOOSItems')) {
			validationScroll('oos-summary');
		}
	}
};

export const validateZipCode = (zip: string, countryCode: string) => {
	if (countryCode === 'US') {
		if (!/^\d{5}$/.test(zip)) {
			return false;
		}
		return true;
	}
	if (!zip || zip.trim() === '' || zip.length < 3) {
		return false;
	}
	const validateZip = validatePostalCode(countryCode, zip);
	if (validateZip !== true) {
		return false;
	}
	return true;
};

export const validatePostalCode = (countryCode: string, zipCode: string) => {
	return postalCodes.validate(countryCode, zipCode);
};

export const resetErrorsObject = () => {
	return {
		countryCode: undefined,
		'contactInformation.email': undefined,
		'contactInformation.phoneNumber': undefined,
		'contactInformation.phonePlaceholderPattern': undefined,
		'shippingAddress.firstName': undefined,
		'shippingAddress.lastName': undefined,
		'shippingAddress.address1': undefined,
		'shippingAddress.address2': undefined,
		'shippingAddress.city': undefined,
		'shippingAddress.zip': undefined,
		'shippingAddress.provinceName': undefined,
		'shippingAddress.province': undefined,
		paymentMethod: undefined,
		'billingAddress.firstName': undefined,
		'billingAddress.lastName': undefined,
		'billingAddress.address1': undefined,
		'billingAddress.address2': undefined,
		'billingAddress.city': undefined,
		'billingAddress.zip': undefined,
		'billingAddress.provinceName': undefined,
		'billingAddress.province': undefined,
		'billingAddress.countryCode': undefined,
		'billingAddress.phoneNumber': undefined,
		'billingAddress.phonePlaceholderPattern': undefined,
		xpayFormReady: undefined,
		stripeFormReady: undefined,
	};
};

export const fieldExists = (countryCode: string, fieldName: string) => {
	const fields = countries[countryCode as keyof typeof countries];
	let have = false;
	const orderedFields =
		fields && 'orderedFields' in fields ? fields.orderedFields : [];
	orderedFields.forEach((item: string[]) => {
		item.forEach((field) => {
			if (field === fieldName) {
				have = true;
			}
		});
	});
	return have;
};

export const getProvinceName = (
	provinceCode: string,
	zones: {
		name: string;
		code: string;
	}[],
) => {
	return zones.filter((item) => item.code === provinceCode)[0];
};

// function to match reactive computed value with original value stored in form
export const matchComputedAndValue = (
	reactiveValue: string,
	formValue: string,
) => {
	return reactiveValue === formValue;
};

export const getUsdTotal = (currency: string, total: number) => {
	const { $conversionRates } = useGlobalStore();
	if (currency !== 'usd') {
		const rate = $conversionRates[currency]!.exchange_rate_to_usd!;
		return parseFloat((total * rate).toFixed(2));
	}
	return total;
};

export const localeStringToNum = (number: string) => {
	return parseFloat(number.replace(/,/g, ''));
};
export const validateUSAddress = async (
	countryCode: string,
	shippingAddress: {
		address1: string;
		address2: string;
		city: string;
		province: string;
		zip: string;
	},
) => {
	if (countryCode === 'US') {
		const address = {
			address: {
				regionCode: 'US',
				addressLines: [
					shippingAddress?.address1!,
					shippingAddress?.address2!,
					`${shippingAddress?.city}, ${shippingAddress?.province}, ${shippingAddress?.zip}`,
				],
			},
		};
		try {
			const response = await fetch(
				`https://addressvalidation.googleapis.com/v1:validateAddress?key=${useRuntimeConfig().public.gmapsPlacesKey}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(address),
				},
			);

			const resp = await response.json();
			const uspsAddress: {
				city: string;
				state: string;
				zipCode: string;
				firstAddressLine: string;
			} = resp.result.uspsData.standardizedAddress;
			if (
				uspsAddress.city.toLowerCase() !== shippingAddress.city.toLowerCase() ||
				uspsAddress.state.toLowerCase() !==
					shippingAddress.province.toLowerCase() ||
				uspsAddress.firstAddressLine.toLowerCase() !==
					shippingAddress.address1.toLowerCase() ||
				uspsAddress.zipCode.toLowerCase() !== shippingAddress.zip.toLowerCase()
			) {
				const suggestedAddress = {
					regionCode: 'US',
					administrativeArea: uspsAddress.state,
					languageCode: 'EN',
					locality: convertToNormalCase(uspsAddress.city),
					postalCode: uspsAddress.zipCode,
					addressLines: convertToNormalCase(uspsAddress.firstAddressLine),
				};
				return suggestedAddress;
			}
		} catch (error) {
			console.log(error);
		}
	}
	return true;
};

export const updateAddressPayload = (
	phone: string,
	firstName: string,
	lastName: string,
	address1: string,
	address2: string | undefined | null,
	city: string | undefined | null,
	zip: string | undefined | null,
	province: string | undefined | null,
	defaultAddress: boolean,
	countryCode: string,
	addressId: string | undefined,
) => {
	return {
		address: {
			first_name: firstName,
			last_name: lastName,
			phone: phone,
			address1: address1,
			address2: address2,
			country: countries[countryCode as keyof typeof countries].name,
			country_code: countryCode,
			city: city,
			default: defaultAddress,
			province: province,
			postal_code: zip,
			zip: zip,
			phone_prefix:
				countries[countryCode as keyof typeof countries].phone[0]?.toString()!,
			phone_country_code: countryCode,
		},
		id: addressId,
	};
};

export const getSubTotalNew = (
	lineItems: LineItemsResponse,
	isHvc: boolean,
	getNativeSubtotal: boolean = false,
	getFormattedPrice: (price: number, noRound?: boolean) => string = () => '',
	currency: string = '',
) => {
	if (lineItems?.length) {
		if (!getNativeSubtotal) {
			let subTotal = 0;
			for (const item of lineItems) {
				if (!isHvc) {
					subTotal = subTotal + Math.ceil(item.price * item.quantity);
				} else {
					subTotal = subTotal + Math.ceil(item.hvc_price!) * item.quantity;
				}
			}
			return subTotal;
		} else {
			let subTotal = 0;
			for (const item of lineItems) {
				if (!isHvc) {
					const originalPrice = isOriginalProductPrice(
						item.compare_at_price,
						// parseFloat(item.compareAtPrice || '0'),
						item.price,
					); //round up usd to .99 and any other currency other than pkr to .00 ceil in case of original price
					const noRound = !(originalPrice && currency !== 'pkr'); //round the values in case of non pkr currency and if product has original price
					const convertedPrice = localeStringToNum(
						getFormattedPrice(item.price, noRound),
					);
					subTotal = subTotal + convertedPrice * item.quantity;
				} else {
					const convertedPrice = localeStringToNum(
						getFormattedPrice(item.hvc_price!, currency !== 'pkr'),
					);
					subTotal = subTotal + convertedPrice * item.quantity;
				}
			}
			return subTotal;
		}
	}
	return 0;
};

export const calculateCheckoutSalePercentage = (
	originalPrice: number,
	salePrice: number,
) => {
	if (!salePrice) {
		return 0;
	}
	const diff = originalPrice - salePrice;
	return Math.round((diff / originalPrice) * 100);
};

interface ThankyouObject {
	order?: number | undefined;
	shipping?: string | undefined;
	tax?: string | undefined;
	order_total?: string | undefined;
	subtotal_line_item?: string | undefined;
	order_name?: string | undefined;
	intent_id?: string | undefined;
	payment_type?: string | undefined;
	created_at?: string | undefined;
	rewards?: string | undefined;
	coupon_code?: string | undefined;
	potential_rewards?: string | undefined;
	special_customer_discount?: string | undefined;
}
export const generateThankyouObject = (data: ThankyouObject, chash: string) => {
	return {
		data: {
			orderNumber: data.order_name,
			subTotal: data.subtotal_line_item,
			totalTax: data.tax,
			shipping: data.shipping,
			rewards: data.rewards,
			coupon: data.coupon_code,
			paymentMethod: data.payment_type,
			shopifyId: data.order!,
			orderTotal: data.order_total,
			date: data.created_at,
			rewardsToBeEarned: data.potential_rewards,
			specialCustomerDiscount: data.special_customer_discount,
			checkoutHash: chash,
		},
	};
};

// #region old checkout
const isJSON = (value: string) => {
	try {
		JSON.parse(value);
		return true;
	} catch {
		return false;
	}
};

const isObject = (value: string | object) => {
	return typeof value === 'object';
};

const dateFormatter = (getDate: string, showYear: boolean = false) => {
	const date = new Date(getDate);
	const dateParse = date.toDateString().split(' ');
	return !showYear
		? dateParse[2] + ' ' + dateParse[1]
		: dateParse[2] + ' ' + dateParse[1] + ' ' + dateParse[3];
};

const convertToNormalCase = (str: string) => {
	return str.replace(/_/g, ' ').replace(/\b[A-Z]+\b/g, (word) => {
		return word.charAt(0) + word.slice(1).toLowerCase();
	});
};

const validateEmail = (email: string) => {
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
		email,
	);

	return emailRegex;
};
export const sanitizeInput = (event: KeyboardEvent) => {
	if (['e', 'E', '-', '.', '(', ')'].includes(event.key)) {
		event.preventDefault();
	}
};
// used only for logan move to seperate file

export { isJSON, isObject, dateFormatter, convertToNormalCase, validateEmail };
