import {
	useInfiniteQuery,
	useQuery,
	type QueryFunction,
	type QueryKey,
	type UseInfiniteQueryOptions,
} from '@tanstack/vue-query';
import { Type, type Static } from '@sinclair/typebox';
import { validatorFactory } from '@laam/lib/validator';
import { useLoganStore } from '~/stores/logan.ts';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';
import { productsResponseSchema } from '@laam/cms-shared';

const productSchema = Type.Object({
	...productsResponseSchema.properties,
	feed_nodes: Type.Optional(
		Type.Array(
			Type.Object({
				id: Type.Number(),
				title: Type.String(),
				parent_id: Type.Number(),
				logo: Type.String(),
				image: Type.String(),
				valhalla_score: Type.Number(),
				product_count: Type.Number(),
				node_level: Type.Number(),
			}),
		),
	),
});

export type Products = Static<typeof productSchema>;
const PAGE_LIMIT = 24;

const productSchemaValidator = validatorFactory<Products>(productSchema);

// fetch products of nodes
export const fetchNodeProducts = async (
	id: string,
	cursor?: string,
	filters?: Record<string, string>,
) => {
	const url = '/v1/nodes/products';
	const api = createApiInstance();
	const response = await api.get(url, {
		params: {
			...filters,
			cursor,
			limit: PAGE_LIMIT,
			id,
		},
	});
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	const data = await response.data;
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', data);
		return scope;
	});
	throw error;
};

export const fetchCollectionProducts = async (
	handle: string,
	cursor?: string,
	filters?: Record<string, string>,
) => {
	const url = `/v1/collections/${handle}/products`;
	const api = createApiInstance();
	const response = await api.get(url, {
		params: {
			...filters,
			limit: PAGE_LIMIT,
			cursor,
		},
	});
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify collection data listing data');
			console.error('Failed to verify collection data listing data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	throw new Error('Invalid response from server');
};

// fetch products of brands
export const fetchBrandProducts = async (
	brandHandle: string,
	cursor?: string,
	filters?: Record<string, string>,
) => {
	const baseURL = useRuntimeConfig().public.apiBaseUrl;
	const url = `${baseURL}/v1/brands/products`;
	const api = createApiInstance();
	const response = await api.get(url, {
		params: {
			...filters,
			cursor,
			limit: PAGE_LIMIT,
			brand_handle: brandHandle,
		},
	});
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			throw new Error('Validation error: ' + e);
		}
	}
	throw new Error('Invalid response from server');
};

// fetch products of drops
export const fetchDropProducts = async (
	dropHandle: string,
	cursor?: string,
) => {
	const baseURL = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseURL}/v1/drops/products?handle=${dropHandle}&limit=${PAGE_LIMIT}`;
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	const data = await response.data;
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', data);
		return scope;
	});
	throw error;
};

// fetch trending products
export const fetchTrendingProducts = async (
	cursor?: string,
	nodeId?: Array<number>,
	filters?: Record<string, string>,
) => {
	const url = '/v1/products';
	const api = createApiInstance();
	const response = await api.get(url, {
		params: {
			limit: PAGE_LIMIT,
			...(nodeId && { node_id: nodeId.join(',') }),
			...(cursor && { cursor }),
			...(filters && { ...filters }),
		},
	});
	if (response.status === 200) {
		try {
			return productSchemaValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

// fetch query products
export const fetchSearchProducts = async (
	query: string,
	filters: string,
	cursor: string,
	nodeId: string,
) => {
	let url = `/v1/products/search?query=${query}${nodeId ? `&node_id=${nodeId}` : ''}&limit=${PAGE_LIMIT}&${filters}`;
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			return productSchemaValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

export const useProductsList = ({
	queryFn,
	queryKey,
	routeName,
	enabledOverride,
}: {
	queryKey: UseInfiniteQueryOptions['queryKey'];
	queryFn: UseInfiniteQueryOptions<Products>['queryFn'];
	routeName: string;
	enabledOverride?: Ref<boolean>;
}) => {
	const LoganStore = useLoganStore();
	const route = useRoute();
	const enabled = computed(() => {
		if (enabledOverride?.value === false) return false;
		return route.name === routeName;
	});
	const { isSignedIn } = storeToRefs(LoganStore);
	const {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useInfiniteQuery<Products>({
		queryKey: [queryKey, isSignedIn],
		queryFn: queryFn,
		getNextPageParam: (lastPage) =>
			lastPage.cursor.next == '' ? null : lastPage.cursor.next,
		initialPageParam: '',
		refetchOnWindowFocus: false,
		enabled,
	});

	const { $eventClient } = useNuxtApp();
	watch(isFetchingNextPage, (isFetchingNextPage) => {
		if (isFetchingNextPage)
			$eventClient.sendEvent('pagination', {
				page_number: (data.value?.pages.length || 0) + 1,
				location: window.location.href,
			});
	});

	return {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	};
};

// fetch products of drops
export const fetchEntityProducts = async (
	entity: string,
	params: {
		id?: string;
		handle?: string;
		limit: number;
		cursor?: string;
	},
) => {
	const baseUrl = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseUrl}/v1/${entity}/products?limit=${params.limit}`;
	if (params.id) {
		url += `&id=${params.id}`;
	} else {
		url += `&handle=${params.handle}`;
	}
	if (params.cursor) {
		url += `&cursor=${params.cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);

	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);

			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');

	throw error;
};

export const useEntityProducts = (
	entity: string,
	id: Ref<number | undefined>,
	handle: Ref<string | undefined>,
	limit = 10,
) => {
	const enabled = computed(() => !!id.value || !!handle.value);

	const { data, isLoading } = useQuery({
		queryKey: ['entity-products', id, handle],
		queryFn: () =>
			fetchEntityProducts(entity, {
				id: id.value?.toString(),
				handle: handle.value,
				limit: limit,
			}),
		enabled,
	});

	return { data, isLoading };
};

// fetch products count
export const fetchProductsCount = async (filters?: Record<string, string>) => {
	const url = '/v1/products/count';

	const api = createApiInstance();
	const response = await api.get(url, { params: filters });
	if (response.status === 200) {
		try {
			return response.data.count as number;
		} catch (e) {
			const error = new Error('Failed to fetch products count');
			console.error('Failed to fetch products count', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch products count');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

export const fetchCollectionProductCount = async (
	handle: string,
	filters?: Record<string, string>,
) => {
	const url = `/v1/collections/${handle}/products/count`;
	const api = createApiInstance();
	const response = await api.get(url, {
		params: {
			...filters,
		},
	});
	if (response.status === 200) {
		try {
			return response.data.count as number;
		} catch (e) {
			const error = new Error('Failed to verify collection data listing data');
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	throw new Error('Invalid response from server');
};

export const useProductsCount = ({
	routeName,
	queryKey,
	queryFn,
}: {
	routeName: string;
	queryKey: QueryKey;
	queryFn: QueryFunction<number>;
}) => {
	const route = useRoute();
	const enabled = computed(() => {
		return route.name === routeName;
	});
	const { data: count } = useQuery({
		queryKey,
		queryFn,
		enabled,
		staleTime: Infinity,
	});

	return count;
};

const newArrivalsCarouselSchema = Type.Object({
	products: productsResponseSchema.properties.products,
	count: Type.Number(),
});
export type NewArrivalsCarouselResponse = Static<
	typeof newArrivalsCarouselSchema
>;
const newArrivalsCarouselValidator =
	validatorFactory<NewArrivalsCarouselResponse>(newArrivalsCarouselSchema);

export const fetchNewArrivalsProducts = async (
	params: Record<string, string>,
) => {
	const url = '/v1/products/carousel';
	const api = createApiInstance();
	const response = await api.get(url, { params });

	if (response.status === 200) {
		try {
			return newArrivalsCarouselValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to fetch products carousel');
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: JSON.stringify(e || {}),
					reqId: Date.now(),
					loganId: useLoganStore().loganId,
					errorTrace: 'Products carousel repsonse validation failed.',
				});
				return scope;
			});
			throw error;
		}
	}
	throw new Error('Invalid response from server');
};

export const useNewArrivalsProductCarousel = ({
	queryKey,
	queryFn,
	enabledOverride,
}: {
	queryKey: QueryKey;
	queryFn: QueryFunction<NewArrivalsCarouselResponse>;
	enabledOverride?: Ref<boolean>;
}) => {
	const enabled = computed(() => {
		if (enabledOverride?.value === false) return false;
		return true;
	});
	return useQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		enabled,
	});
};
