<script setup lang="ts">
import countries from '~/utils/countries.json';
import { COUNTRY_CURRENCY_MAP } from '~/utils/enums';

interface CountryIcon {
	code: string;
	class: string;
}
const props = withDefaults(defineProps<CountryIcon>(), {
	class: 'h-5xl w-5xl',
});
const countryFlag = computed(() => {
	if (props.code === 'EU') {
		return COUNTRY_CURRENCY_MAP['eur'].flag;
	} else {
		return countries[props.code as keyof typeof countries].flag;
	}
});
</script>
<template>
	<div>
		<NuxtImg
			:src="countryFlag"
			alt="country flag"
			loading="eager"
			:class="props.class"
		/>
	</div>
</template>
